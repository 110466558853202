import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import FloatingInput from 'UI/FloatingInput/FloatingInput';

export default function GetDirectionsBox({ inputcss, btncss, iconcss, ...props }) {
    const [showError, setshowError] = useState(false);


    const checkCurrentLocForNull = (e) => {

        e.preventDefault();
        var val = document.forms["locform"].saddr.value;

        if (!val || (val.length <= 0) || (val.trim() === "")) {

            setshowError(true);

        }
        else {

            document.forms["locform"].submit();
            setshowError(false);
            return true;
        }


    }


    return (
        <>
            <form name="locform" action="https://maps.google.com/maps" method="get" target="_blank" onSubmit={checkCurrentLocForNull}>


                <div className='mt-2 space-x-2 d-flex'>
                    <div className='float-left '>
                        <FloatingInput name="saddr" classm={inputcss} label="Enter your location"></FloatingInput>

                        <input type="hidden" name="daddr" value="10 Victoria Square Colborne,ON K0K 1S0 Canada" />

                    </div>
                    <div className='mt-1'>

                        <Button className='space-x-2 border-0 border-gray-100 bg-secondary' type="submit" size="lg" active>
                            <i className="text-xs text-white bi bi-compass"></i>
                            <span className="text-sm font-semibold text-white iconcss">
                                Get Directions
                            </span>
                        </Button>
                        {/* <button
                            type="submit"
                            className={btncss}

                        >

                            <div className="space-x-2 d-flex col-sm-12">
                                <div className={iconcss + " space-x-2"}>

                                    <i className="float-left text-xs bi bi-compass"></i>
                                    <label className="text-xs font-semibold">
                                        Get Directions
                                    </label>
                                </div>
                            </div>
                        </button> */}
                        {/* <button type="submit" className={btncss}>Get Directions</button> */}
                    </div>

                </div>
                {showError && <span className='text-sm text-red'>* Current Location is required</span>}
            </form >
        </>
        // </form >
    )
}
// "bg-gray-300"
// "btn h-8 btn-outline-secondary btn-sm mt-3
