import React from 'react';
import { ErrorMessage, Field, useField } from 'formik';

export const InputField = ({ star, inputtabindex, label, type, required, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      {star ? "*" : ""} <label htmlFor={field.name} className="blockx uppercase tracking-wide text-gray-700 text-sm  font-semibold">{label}</label>
      <Field tabIndex={inputtabindex}
        className={`form-control shadow-none form-input ${meta.touched && meta.error && 'is-invalid'}`}
        {...field} {...props}
        autoComplete="off"
        type={type}
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  )
}