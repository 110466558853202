import PromotionMonthlyMobile from "Mobile/components/PromotionMonthlyMobile";
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MonthlyPromotion from "../components/MonthlyPromotion/MonthlyPromotion";
import "./../components/MonthlyPromotion/styles.css";
import './styles.css';

export default function PromotionMonthly() {
    const [ currentMonth, setcurrentMonth ] = useState();

    // const containerStyle = {


    //      backgroundImage: "url(" + process.env.REACT_APP_IMAGE_URL + "banner-2.webp)",
    // };

    const monthName = () => {
        var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        var d = new Date();
        var monthNamex = months[ d.getMonth() ];
        return monthNamex;
    }

    useEffect(() => {
        setcurrentMonth(monthName);
    }, [])
    return (
        <>
            <div className="mt-4 text-center ">
                <span className="justify-center titleSubHeader steelblue-color">
                    Monthly Promotions
                </span>
            </div>

            <section
                id="video-2"
                // style={containerStyle}
                className="p-2 pb-3 mt-2 align-middle rounded-lg containerStyle video-section division "
            >
                <header>
                    <div className="ml-6 space-x-2 row d-flex align-items-center ">
                        <div className="txt-block ">
                            <div className="py-2 space-x-1 text-white d-flex">
                                <p className="font-bold text-white md:text-3xl sm:text-xs">
                                    {currentMonth}
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
                <BrowserView>
                    <MonthlyPromotion />
                </BrowserView>

                <MobileView>
                    <PromotionMonthlyMobile />
                </MobileView>

                {/* <footer className="mt-2">
                    <span className="font-semibold text-white">
                        At Colborne Family dental , Get a brighter and Show off your pearly white smile in about an hour with Zoom Teeth Whitening!.
                        Zoom Whitening for a confident, beautiful smile!
                    </span>
                </footer> */}
            </section>
        </>
    );
}
