import BreadCrumbCDC from "components/Breadcrumbs/NewBreadCrumb";
import React, { useEffect } from "react";

export default function Periodontal() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="prose">
            <BreadCrumbCDC title="Blog" name="Periodontal Disease"></BreadCrumbCDC>
            <main className=" blog">
                <div className="flex justify-between max-w-screen-xl px-4 mx-auto ">
                    <article className="w-full">
                        <header className="mb-4 lg:mb-6 not-format">
                            <h1 className="blog-Title">
                                Periodontal disease: Recognizing the signs and seeking treatment{" "}
                            </h1>
                        </header>

                        <div>
                            <p className="blog-Intro">
                                Periodontal disease, commonly known as gum disease, is a
                                prevalent oral health issue that can have severe consequences if
                                left untreated. In this blog post, we will explore the various
                                stages of periodontal disease, how to recognize the signs, and
                                the importance of seeking prompt treatment to maintain optimal
                                oral health.
                            </p>
                        </div>

                        <div className="blog-content">
                            <h3>Understanding periodontal disease:</h3>
                            <p>
                                Periodontal disease is an inflammatory condition that affects
                                the gums and the supporting structures of the teeth. It is
                                primarily caused by bacterial plaque that accumulates on the
                                teeth and eventually hardens into tartar. The two main stages of
                                periodontal disease are:
                            </p>
                            <ul className="blog-list">
                                <li>
                                    {" "}
                                    Gingivitis: This early stage of gum disease is characterized
                                    by inflammation and irritation of the gums, which may appear
                                    red, swollen, and bleed easily during brushing or flossing.
                                    With appropriate oral care and professional dental
                                    intervention, gingivitis can be reversed.
                                </li>
                                <li>
                                    Periodontitis: If left untreated, gingivitis can progress to
                                    periodontitis, a more severe form of gum disease. In this
                                    stage, the gums begin to pull away from the teeth, forming
                                    pockets that become infected. Over time, the infection can
                                    lead to the breakdown of the bone and connective tissue that
                                    hold the teeth in place, ultimately resulting in tooth loss.
                                </li>
                            </ul>

                            <h3>Recognizing the signs of periodontal disease</h3>
                            <p>
                                Being able to identify the early signs of periodontal disease is
                                crucial for seeking timely treatment and preventing more severe
                                complications. Some common symptoms to watch for include:
                            </p>
                            <ul className="blog-list">
                                <li>Red, swollen, or tender gums</li>
                                <li>Bleeding gums during brushing or flossing </li>
                                <li>
                                    Unrelenting foul breath or an unpleasant taste persisting in
                                    the mouth
                                </li>
                                <li>
                                    Receding gums or teeth that appear longer than before Loose
                                    teeth or a change in the way your teeth fit together when you
                                    bite
                                </li>
                            </ul>
                            <p>
                                If you notice any of these symptoms, it's essential to consult
                                your dentist as soon as possible.{" "}
                            </p>

                            <h3>Seeking treatment for periodontal disease</h3>
                            <p>
                                {" "}
                                The first step in treating periodontal disease is a thorough
                                dental examination to determine the extent of the issue. Based
                                on the severity of the disease, your dentist will recommend an
                                appropriate treatment plan, which may include:
                            </p>
                            <ul className="blog-list">
                                <li>
                                    Deep Cleaning (Scaling and Root Planing): This non-surgical
                                    procedure involves removing plaque and tartar from above and
                                    below the gumline, followed by smoothing the root surfaces to
                                    help the gums reattach to the teeth.
                                </li>
                                <li>
                                    {" "}
                                    Medications: Your dentist may prescribe antibiotics,
                                    antimicrobial mouthwashes, or other medications to help
                                    control the infection and promote healing.
                                </li>
                                <li>
                                    {" "}
                                    Surgical treatments: in more advanced cases of periodontitis,
                                    surgical treatments such as flap surgery, bone grafts, or
                                    guided tissue regeneration may be necessary to repair the
                                    damage caused by the disease.
                                </li>
                            </ul>
                            <h3>Preventing periodontal disease</h3>
                            <p>
                                The best way to prevent periodontal disease is by maintaining
                                excellent oral hygiene and scheduling regular dental checkups.
                                Here are some preventive measures to help keep your gums
                                healthy:
                            </p>
                            <ul className="blog-list">
                                <li>
                                    Brush your teeth at least twice a day using a soft-bristled
                                    toothbrush and fluoride toothpaste.{" "}
                                </li>
                                <li>
                                    Floss daily to remove plaque and food particles from between
                                    your teeth and under your gumline.{" "}
                                </li>
                                <li>
                                    Rinse with an antimicrobial mouthwash to help control plaque
                                    and reduce the risk of gum disease
                                </li>
                                <li>
                                    {" "}
                                    Visit your dentist every six months for a professional
                                    cleaning and oral examination
                                </li>
                            </ul>
                            <p className="blog-conclusion">
                                Periodontal disease is a serious oral health issue that requires
                                prompt attention to prevent long-term damage. By recognizing the
                                signs of gum disease and seeking appropriate treatment, you can
                                protect your teeth and gums and maintain a healthy smile for
                                years to come.
                            </p>
                        </div>
                    </article>
                </div>
            </main>
        </div>
    );
}
