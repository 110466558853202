// src/pages/TargetPage.js
import React from 'react';

const TargetPage = () => {
    return (
        <div className="container">
            <div className="content">
                <p className="intro">
                    At Colborne Family Dental, we believe that everyone deserves access to high-quality dental care, regardless of their financial situation. That's why we're proud to announce that we now accept the Canadian Dental Care Plan, making our services even more accessible to individuals and families across Canada.
                </p>

                <h2 className="sub-header">What is the Canadian Dental Care Plan?</h2>
                <p>
                    The Canadian Dental Care Plan is a government-sponsored program aimed at helping eligible individuals access essential dental services at an affordable cost. It provides coverage for a wide range of dental treatments, including preventive care, restorative procedures, and emergency services, making it easier for Canadians to maintain good oral health.
                </p>

                <h2 className="sub-header">Benefits of Choosing Colborne Family Dental with the Canadian Dental Care Plan:</h2>

                <h3 className="sub-sub-header">Affordable Care</h3>
                <p>
                    By accepting the Canadian Dental Care Plan, we strive to make dental care more affordable for our patients. With the plan covering a significant portion of the cost of dental treatments, individuals and families can receive the care they need without breaking the bank.
                </p>

                <h3 className="sub-sub-header">Comprehensive Services</h3>
                <p>
                    At Colborne Family Dental, we offer a comprehensive range of dental services to meet the diverse needs of our patients. Whether you require a routine check-up and cleaning, a filling or extraction, or more complex procedures such as crowns or implants, our experienced team is here to help.
                </p>

                <h3 className="sub-sub-header">Emphasis on Prevention</h3>
                <p>
                    We believe in the importance of preventive care in maintaining good oral health. With regular check-ups, cleanings, and personalized oral hygiene education, we help our patients prevent dental problems before they arise, saving them time, money, and discomfort in the long run.
                </p>

                <h3 className="sub-sub-header">Patient-Centered Approach</h3>
                <p>
                    Our team is dedicated to providing compassionate, patient-centered care in a welcoming and comfortable environment. We take the time to listen to our patients' concerns, explain their treatment options thoroughly, and empower them to make informed decisions about their dental health.
                </p>

                <h2 className="sub-header">How to Access Dental Care at Colborne Family Dental with the Canadian Dental Care Plan:</h2>
                <p>
                    If you're eligible for the Canadian Dental Care Plan and are looking for a dental provider in the area, getting started with Colborne Family Dental is easy. Simply contact our office to schedule an appointment, and let us know that you'll be using the Canadian Dental Care Plan for payment.
                </p>
                <p>
                    During your visit, our friendly staff will assist you with the necessary paperwork and ensure that you understand your coverage and any out-of-pocket expenses. We're committed to making the process as smooth and seamless as possible, so you can focus on getting the care you need.
                </p>
                <p>
                    At Colborne Family Dental, we're dedicated to making dental care accessible to all Canadians. By accepting the Canadian Dental Care Plan, we're proud to be part of a system that prioritizes oral health and helps individuals and families across the country maintain healthy smiles for life. If you're in need of dental care and are covered by the Canadian Dental Care Plan, we invite you to schedule an appointment with us today and experience the difference that compassionate, high-quality dental care can make.
                </p>
            </div>
        </div>
    );
};

export default TargetPage;
