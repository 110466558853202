import React, { useState } from "react";
import { Image } from "react-bootstrap";
import './styles.css';

export default function PromotionMonthlyMobile() {
  const url = "imagesx/webp/promo/C2.webp?v="
  const [ rnd, setRnd ] = useState(Math.floor(Math.random() * 50));
  const [ imgx, setImgx ] = useState(url + rnd);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col col-md-5 col-sm-12 col-lg-4 ">
            <Image
              className=" mobileImgSlider"
              alt="Monthly Promotion"
              src={imgx}
              rounded
            ></Image>
          </div>
        </div>
      </div>
    </>
  );
}
