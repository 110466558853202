import React from "react";

export default function TeethWhitening() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Teeth Whitening </h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/TeethWhitening/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md "
          />
          <img
            src="imagesx/colborne/services/TeethWhitening/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div className="mb-10">
          <p className="py-1 para">
            Whether you take perfect care of your teeth or not, stain-heavy
            foods and beverages are unavoidable. Through regular wear and tear
            or even that daily cup of coffee, our teeth start to get discoloured
            over time and could really benefit from a whiter smile. These are
            all great options for mild stains that are caught early, but for
            those more intense, long lasting discolorations and stains, we can
            help. Our highly knowledgeable dental team offers a variety of
            different teeth whitening solutions, including:"
          </p>
          <div>
            <p className="py-1 para">
              Start simple with regimented brushing and flossing; then try using
              home whitening kits such as whitening strips, pens, or mouth
              trays.{" "}
            </p>
            <div className="py-2">
              <span className="font-semibold">Home Whitening Kits:</span>
              <p className="py-1 parax">
                Get a personalized home whitening kit created especially for you
                by our dental specialists. Our personalized take-home kits will
                include a bleaching gel and custom-made mouth trays, and take
                approximately 1-2 weeks to start showing results. Our bleaching
                gel has a higher intensity and is much more effective than
                over-the-counter products, helping you achieve a brighter smile.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
