import React, { useState } from "react";
import { Image } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "./styles.css";
export default function MonthlyPromotion() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            // slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    const url1 = "imagesx/webp/promo/C1.webp?v="
    const url2 = "imagesx/webp/promo/C2.webp?v="

    const [ rnd, setRnd ] = useState(Math.floor(Math.random() * 50));
    const [ img1, setImg1 ] = useState(url1 + rnd);
    const [ img2, setImg2 ] = useState(url2 + rnd);

    return (
        <>

            <div className="rounded-lg row">
                <div

                    className="p-1 ml-10 col col-md-7 col-sm-12 col-lg-7"
                >
                    <Image className="imgSlider"
                        // key={key}
                        rounded
                        alt="promotion image"
                        // src={"imagesx/promo/webp/C1.webp"}
                        src={img1}
                    />
                    {/* <video
                        className="videoInsert"
                        playsInline
                        controls
                        autoPlay
                        loop
                        muted
                        id="promosvideo"
                        controlsList="nodownload"

                    >
                        <source
                            src="assets/video/sep.mp4"
                            type="video/mp4"
                        ></source>
                    </video> */}
                </div>
                <div className="col col-md-5 col-sm-12 col-lg-4 carousalSlider">
                    <Image className="imgSlider"
                        // key={key}
                        rounded
                        alt="promotion image"
                        // src={"imagesx/webp/promo/C2.webp"}
                        src={img2}
                    />
                    {/* <Carousel
                        arrows={false}
                        renderDotsOutside
                        renderButtonGroupOutside={false}
                        fade
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={6000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={3000}
                        className="self-center "
                        containerClass="carousel-container "
                        removeArrowOnDeviceType={[ "tablet", "mobile" ]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-20-px"
                    >
                        {Array.from(Array(3).keys()).map((item, key) => {
                            return (
                                <Image className="imgSlider"
                                    key={key}
                                    rounded
                                    alt="promotion image"
                                    src={"imagesx/webp/promo/" + (item + 1) + ".webp"}

                                />
                            );
                        })}
                    </Carousel> */}
                </div>
            </div>
        </>
    );
}
