import React from 'react';

export default function Sidebar() {
  // const getapi = () => {
  //   // Create();
  // };
  return (
    <>
      {/* <button onClick={getapi} type="button" name="apibtn" className='btn btn-primary' /> */}
      <aside id="sidebar" className="px-6 col-lg-4 sm:mt-5 sm:mb-5">
        <div id="txt-widget" className="sidebar-div ">
          {/* <div className="clearfix txt-widget-unit mb-15 d-flexx align-items-center"> */}

          <div className='flex flex-wrap'>
            <p className="px-3 h5-md steelblue-color">
              In a Hurry? Please call
            </p>

            <p className="mb-4 font-semibold md:mt-2 lg:mt-2 xl:mt-2 mx-autoxs h2 blue-color">
              <i className="steelblue-color bi bi-telephone"></i> 905-355-1419
            </p>
          </div>
          {/* <div className="mt-3 -ml-5 d-flex">

              <p className="mx-auto font-semibold h2 blue-color">
                <i className="steelblue-color bi bi-telephone"></i> 905-355-1419
              </p>
            </div> */}
          {/* </div> */}

          <div className="row ">
            <div className="col-xl-12">
              {/* <h5 className="mt-2 h5-sm steelblue-color">
            REQUEST FORM
          </h5> */}
              {/* Option #1 */}
              {/* Option #2 */}
              <div className="box-list">
                <div className="mt-4 box-list-icon blue-color">
                  {/*  <i className="bi bi-chevron-double-right"></i> */}
                  <i className="bi bi-chevron-double-right"></i>
                </div>
                <div className='flex'>
                  <p className="p-sm">
                    <span className="font-semibold">
                      REQUIRED INFORMATION:
                    </span>
                  </p>
                  <p className="mx-auto space-x-2 text-right sm:visible md:hidden lg:hidden xl:hidden">

                    <i className="bi bi-arrow-up-circle text-blue-600"></i>
                    <a href="#top" className="text-sm text-blue-600">Move up</a>
                  </p>
                </div>
                <hr />
                {/* <p> */}
                <div className='box-list'>
                  <ul className="space-x-5 list-decimal list-inside">
                    <li className=" ul.content-listx lix txt-blockx ">Name</li>
                    <li className="float-lg-right txt-blockx ">Email
                      <span className="font-Roboto"> (or)</span> Contact
                      Number
                    </li>
                    <li>Appointment Date {"&"} Time</li>
                    <li>Visit Reason</li>
                  </ul>
                </div>
                {/* </p> */}
              </div>
              <div className="box-list">
                <div className="box-list-icon blue-color">
                  <i className="bi bi-chevron-double-right"></i>
                </div>
                <p className="p-sm">Fill out the form on the Left</p>
              </div>
              {/* Option #3 */}
              <div className="box-list">
                <div className="box-list-icon blue-color">
                  <i className="bi bi-chevron-double-right"></i>
                </div>
                <p className="p-sm">
                  If you would like to schedule a new appointment,
                  please tell us what you want an appointment for
                </p>

              </div>
              <div className="box-list">
                <div className="box-list-icon blue-color">
                  <i className="bi bi-chevron-double-right"></i>
                </div>
                <p className="p-sm">
                  After you submit, Sit back, relax & we’ll contact you!
                </p>
              </div>{" "}
              <div className="box-list">
                <div className="box-list-icon blue-color">
                  <i className="bi bi-chevron-double-right"></i>
                </div>
                <p className="p-sm">
                  <span className="font-semibold"> Please note: </span>{" "}
                  Apppointment booking is temprorary unless confirmed by
                  our representative.
                </p>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  )
}
