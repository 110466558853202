import BreadCrumbCDC from "components/Breadcrumbs/NewBreadCrumb";
import ScrollButton from "components/ScrollTop/ScrollButton";
import Team from "components/TeamProfile/Team";
import TeamProfileFull from "components/TeamProfile/TeamProfileFull";
import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { ABOUTUS } from "assets/helmet";
import useDocumentTitle from "useHook/useDocumentTitle";
import { HelmetTag } from "components/HelmetTag";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useDocumentTitle(ABOUTUS.title, ABOUTUS.desc);

  return (
    <>

      <div id="apt" className="container h-full ">
        <BreadCrumbCDC title="About Us"> </BreadCrumbCDC>
        <div className="text-center ">
          <span className="font-light h1 steelblue-color font-lato">
            About Us
          </span>
          <Row>
            <Team />
          </Row>
          <Row>
            <TeamProfileFull />
          </Row>
        </div>
        {/* End row */}
      </div>
      <ScrollButton />
    </>
  );
};

export default AboutUs;
