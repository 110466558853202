import React from "react";

export default function PorcelainVeneers() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Porcelain Veneers </h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/PorcelainVeneers/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md "
          />
          <img
            src="imagesx/colborne/services/PorcelainVeneers/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
          <img
            src="imagesx/colborne/services/PorcelainVeneers/3.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div className="mb-10">
          <p className="py-1 para">
            <span className="font-semibold"> Porcelain veneers</span> are a type
            of cosmetic dental restoration that helps to make your smile shine
            brighter and whiter. Immediately fix chipped teeth, cover up heavy
            stains, or close gaps between teeth with porcelain veneers.
          </p>
          <p className="py-1 para">
            Cosmetic procedures are a permanent process that cannot be reversed,
            so it’s vital to be certain before making an informed decision.
            Porcelain veneers are bonded to your natural teeth’s enamel, and can
            be coloured to match the shade of your other teeth, while providing
            strength and protection. Unlike dental crowns or Invisalign
            Invisible braces, veneers are less obstructive and can easily
            correct minor imperfections such as a stained or chipped tooth.
            Porcelain is used as the primary material for dental veneers because
            of its colour and durability.
          </p>
          <p className="py-1 para">
            With proper care and routine checkups, porcelain veneers are a
            fantastic, long term solution that doesn’t require an orthodontic
            procedure or treatment.During the first appointment, the dentist
            will examine the tooth, clean it, and remove a tiny amount of enamel
            to make room for the veneer. Once an impression is taken and sent to
            a dental lab, a temporary veneer will be placed until the permanent
            one has been created. Once ready, the veneer will be securely bonded
            to the tooth, and then polished afterward.
          </p>
          <p className="py-1 para">
            Speak with any one of our dental specialists, as our knowledgeable
            staff can help you book a visit and find out if porcelain veneers
            are the best solution for you.
          </p>
        </div>
      </div>
    </>
  );
}
