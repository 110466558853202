import React, { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <section className="section-titlex">

      <h1>What type of information do we collect?</h1>
      <p>
        When you visit our website, we collect any information that you give us.
        For example, if you fill out the contact form, that information will be
        stored on our website and sent to the appropriate contact at our
        company. We may also collect information through web beacons, cookies,
        and other analytics that shows us how you came to view our website and
        whether or not it was through an advertisement. Additionally, if you
        share one of our web pages or blogs through your social media account,
        we will collect information on that activity. We may also obtain
        information from publicly available sources on your browsing activity
        and interests. While we do not collect personally sensitive data, we may
        collect information on the device you are using to visit our website
        along with your geographic location in order to improve your user
        experience. From time to time, we or our partners may send a cookie or
        anonymous identifier to your device. When the law requires it, we obtain
        a consent before collecting and storing user information.
      </p>
      <h1>Partners that collect information</h1>
      <p>
        By visiting our website, your information may also be gathered by Google
        since we use Google Analytics to provide us with information about our
        website and the visitors to it. If you reached our site through a
        different search engine, information might be gathered by them, as well.
        This information is for analytical purposes and collected automatically
        when visiting our site. The information is not manually transferred and
        if you want more information on Google's Privacy Policy, you can click
        here.
      </p>
      <h1>How the information is used</h1>
      <p>
        By understanding how a viewer came to visit our website and what they
        did there, we can improve the overall user experience of our visitors.
        By collecting data, we can conduct research and identify ways that we
        can improve. We may use it to make changes to our website in order to
        make it more user-friendly or to improve the content on our site. In the
        future, this information may be used to improve your experience
        specifically or to make recommendations about how we can assist you. If
        you filled out a contact form, your information would be used so that
        our staff can reach out to you via email or a phone call. Additionally,
        if you sign up to be on our email list, your information will be used so
        that we can send communication to you. We may also use your information
        to provide you with promotional offers or incentives to use our products
        and services. This may be on our website or other places across the
        Internet and be provided directly by us or one of our advertising
        partners.
      </p>
      <h1>With whom we share the information</h1>
      <p>
        When we collect information about you, it is generally used for our
        purposes internally. We may share information with Google or our
        advertising partners for the purposes described above. We may also share
        your information with companies that provide services and support to us,
        as it pertains to them fulfilling their duties. If you comment on our
        blog posts or engage with us on social media, your profile information
        will be shared along with any comments that you post – typically this is
        viewable by the general public. We do not share any personal or
        sensitive information without your consent. However, there may be
        instances where we are required to share your information by law and if
        so, will comply as necessary.
      </p>
      <h1>Protecting your information</h1>
      <p>
        We have taken steps to protect your information by restricting the
        access to your information to only select employees that would need it
        in order to perform their job duties. We also review our collection and
        storage policies on a regular basis to determine if they need to be
        improved.
      </p>
    </section> */}

      {/* <!-- TERMS & PRIVACY
			============================================= --> */}
      <section id="terms-page" className="wide-80 terms-section division">
        <div className="container">
          <div className="row">
            {/* <!-- TERMS CONTENT --> */}
            <div className="col-lg-12">
              <div className="terms-holder pr-30">
                {/* <!-- TERMS BOX --> */}
                <div className="terms-box">
                  <h3 className="h3-md steelblue-color">
                    {" "}
                    Colborn Family Dental Privacy Policy{" "}
                  </h3>
                  {/* <!-- Terms Title--> */}
                  <div className="terms-title mt-2">
                    {/* <!-- Text --> */}
                    <p className="grey-color">Last Updated: March 31, 2019</p>

                    {/* <!-- Text --> */}
                    <p className="p-notice steelblue-color">
                      PLEASE READ THESE TERMS AND CONDITIONS (THE ‘AGREEMENT’)
                      IN THEIR ENTIRETY BEFORE USING OR RECEIVING ANY FEATURES
                      (AS DEFINED BELOW).
                    </p>

                    {/* <!-- Text --> */}
                    <p>
                      Our Privacy Policy covers any information that is
                      collected about you and your activities while visiting our
                      website, how that information is stored, and shared. Our
                      Privacy Policy also covers the relationships we have with
                      third parties.
                    </p>
                  </div>
                </div>

                {/* <!-- TERMS BOX --> */}
                <div className="terms-box">
                  {/* <!-- Title --> */}
                  <h5 className="h5-xl steelblue-color">
                    1. Collection of Information
                  </h5>

                  {/* <!-- Text --> */}
                  <p>
                    When you visit our website, we collect any information that
                    you give us. For example, if you fill out the contact form,
                    that information will be stored on our website and sent to
                    the appropriate contact at our company. We may also collect
                    information through web beacons, cookies, and other
                    analytics that shows us how you came to view our website and
                    whether or not it was through an advertisement.
                    Additionally, if you share one of our web pages or blogs
                    through your social media account, we will collect
                    information on that activity. We may also obtain information
                    from publicly available sources on your browsing activity
                    and interests. While we do not collect personally sensitive
                    data, we may collect information on the device you are using
                    to visit our website along with your geographic location in
                    order to improve your user experience. From time to time, we
                    or our partners may send a cookie or anonymous identifier to
                    your device. When the law requires it, we obtain a consent
                    before collecting and storing user information.
                  </p>

                  {/* <!-- List --> */}
                </div>

                {/* <!-- TERMS BOX --> */}
                <div className="terms-box">
                  {/* <!-- Title --> */}
                  <h5 className="h5-xl steelblue-color">
                    2. How We Use Your Information
                  </h5>

                  {/* <!-- Text --> */}
                  <p>
                    By understanding how a viewer came to visit our website and
                    what they did there, we can improve the overall user
                    experience of our visitors. By collecting data, we can
                    conduct research and identify ways that we can improve. We
                    may use it to make changes to our website in order to make
                    it more user-friendly or to improve the content on our site.
                    In the future, this information may be used to improve your
                    experience specifically or to make recommendations about how
                    we can assist you. If you filled out a contact form, your
                    information would be used so that our staff can reach out to
                    you via email or a phone call. Additionally, if you sign up
                    to be on our email list, your information will be used so
                    that we can send communication to you. We may also use your
                    information to provide you with promotional offers or
                    incentives to use our products and services. This may be on
                    our website or other places across the Internet and be
                    provided directly by us or one of our advertising partners.
                  </p>
                </div>

                {/* <!-- TERMS BOX --> */}
                <div className="terms-box">
                  {/* <!-- Title --> */}
                  <h5 className="h5-xl steelblue-color">
                    3. Sharing Your Information
                  </h5>

                  {/* <!-- Text --> */}
                  <p>
                    We share your Personal Information with third parties to
                    help us use your Personal Information, as described above.
                    For example, we use Google Analytics to help us understand
                    how our customers use the site–you can read more about how
                    Google uses your Personal Information here:
                    https://www.google.com/intl/en/policies/privacy/. You can
                    also opt-out of Google Analytics here:
                    https://tools.google.com/dlpage/gaoptout. Finally, we may
                    also share your Personal Information to comply with
                    applicable laws and regulations, to respond to a subpoena,
                    search warrant or another lawful request for information we
                    receive, or to otherwise protect our rights. Additionally,
                    you can opt out of some of these services by visiting the
                    Digital Advertising Alliance’s opt-out portal at:
                    https://optout.aboutads.info/.
                  </p>
                </div>

                {/* <!-- TERMS BOX --> */}
                <div className="terms-box">
                  {/* <!-- Title --> */}
                  <h5 className="h5-xl steelblue-color">
                    4. Managment of Personal Data
                  </h5>

                  {/* <!-- Text --> */}
                  <p>
                    In Canada, the Personal Information Protection and
                    Electronic Documents Act (or PIPEDA), governs the collection
                    and use of personal information and how it’s protected. We
                    are not allowed to disclose personal information about you
                    without your written permission. Certain information
                    collected from you and about you is used within the context
                    of providing our services to you. The information we collect
                    is not shared with, sold or rented to others except under
                    certain circumstances and which your use of the service is
                    deemed to provide to us a valid consent to disclose. In
                    plain English, this just means that we aren’t going to sell
                    you out to any old mailing list or cold-caller. All the data
                    we collect is used to provide you with the service you
                    requested and to improve your experience with our website
                    and our business in general.
                  </p>
                </div>

                {/* <!-- TERMS BOX --> */}
                <div className="terms-box">
                  {/* <!-- Title --> */}
                  <h5 className="h5-xl steelblue-color">
                    5. Right to Access, Correct and Delete Personal Data
                  </h5>

                  <p>
                    <span className="steelblue-color">
                      Restrictions and Suspensions:
                    </span>
                    We may revoke your or block access to our web site if found
                    any illegal activities . The said activity will be informed
                    to relevant authorities for further action.
                  </p>
                </div>

                {/* <!-- TERMS BOX --> */}
                <div className="terms-box">
                  {/* <!-- Title --> */}
                  <h5 className="h5-xl steelblue-color">7. Notices</h5>

                  {/* <!-- Text --> */}
                  <p>
                    We may keep updating our privacy policy time to time to
                    reflect changes in our practices , legal or for other
                    regulatiory reasons.
                  </p>
                </div>

                <div className="terms-box">
                  <h5 className="h5-xl steelblue-color">Contact Us:</h5>

                  <p>
                    For any further information you may email us at
                    info@colbornefamilydental.ca or call us at (905) 355 1419
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
