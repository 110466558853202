// src/components/Popup.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Popup.css';

const Popup = ({ show, onClose }) => {
    if (!show) return null;

    const handleLinkClick = () => {
        onClose();
    };
    const url1 = "imagesx/webp/popup2024V1.webp?v=";

    return (

        <div
            className="popup-overlay" title='Click here to discover more about CDCP'>

            <div className="popup-content">
                <button className="popup-close" onClick={onClose}>X</button>
                <Link to="/targetpage" onClick={handleLinkClick}>
                    <img src={url1} alt="Popup" className="popup-image" />
                </Link>
            </div>

        </div >

    );
};

export default Popup;
