import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import * as Yup from "yup";

export const disableAppointTimes = [
    setHours(setMinutes(new Date(), 30), 17),
    setHours(setMinutes(new Date(), 0), 18),
    setHours(setMinutes(new Date(), 30), 18),
    setHours(setMinutes(new Date(), 0), 19),
    setHours(setMinutes(new Date(), 30), 19),
    setHours(setMinutes(new Date(), 0), 20),
    setHours(setMinutes(new Date(), 30), 20),
    setHours(setMinutes(new Date(), 0), 21),
    setHours(setMinutes(new Date(), 30), 21),
    setHours(setMinutes(new Date(), 0), 22),
    setHours(setMinutes(new Date(), 30), 22),
    setHours(setMinutes(new Date(), 0), 23),
    setHours(setMinutes(new Date(), 30), 23),
    setHours(setMinutes(new Date(), 0), 0),
    setHours(setMinutes(new Date(), 30), 0),
    setHours(setMinutes(new Date(), 0), 1),
    setHours(setMinutes(new Date(), 30), 1),
    setHours(setMinutes(new Date(), 0), 2),
    setHours(setMinutes(new Date(), 30), 2),
    setHours(setMinutes(new Date(), 0), 3),
    setHours(setMinutes(new Date(), 30), 3),
    setHours(setMinutes(new Date(), 0), 4),
    setHours(setMinutes(new Date(), 30), 4),
    setHours(setMinutes(new Date(), 0), 5),
    setHours(setMinutes(new Date(), 30), 5),
    setHours(setMinutes(new Date(), 0), 6),
    setHours(setMinutes(new Date(), 30), 6),
    setHours(setMinutes(new Date(), 0), 7),
    setHours(setMinutes(new Date(), 30), 7),
    setHours(setMinutes(new Date(), 0), 8),
];

export const phoneRegExp = /\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*/;

export const validate = Yup.object().shape(
    {
        name: Yup.string()
            .min(1, "Must be 1 character or more")
            .required("Name is a required field"),

        email: Yup.string()
            .email()
            .when("phone", {
                is: (phone) => !phone || phone.length === 0,
                then: Yup.string()
                    .email()
                    .required("Email or contact number is required"),
                otherwise: Yup.string(),
            }),
        phone: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .when("email", {
                is: (email) => !email || email.length === 0,
                then: Yup.string().required("Contact number or Email is required"),
            }),
    },

    [["email", "phone"]]
);

export const getAllSundays = (from, to) => {
    var dates = [];

    while (from < to) {
        if (from.getDay() === 0) dates.push(new Date(from));
        from.setDate(from.getDate() + 1);
    }

    return dates;
};
