import React from 'react';
import { Image } from 'react-bootstrap';
// import Zoom from 'react-medium-image-zoom';
// import 'react-medium-image-zoom/dist/styles.css';

export default function Team() {
  // const navigate = useNavigate();
  // const baseUrl = "https://res.cloudinary.com/webappstack/image/upload/v1648610595/cdc/docs/";
  return (
    <>
      {/* <Container> */}


      <section className='section' >
        <Image className="mt-2 aboutus-image" style={{ width: "80%" }} src="imagesx/colborne/docs/webp/Team.webp" alt="about-image" />
        <div className="mt-8 row">
          <div className="col-md-6 col-lg-6 vh-10">
            {/* <Image className="aboutus-image" src="imagesx/colborne/docs/webp/Team.webp" alt="about-image" /> */}
            {/* <Image className="" src="imagesx/webp/team/about-page.webp" alt="about-image" /> */}

          </div>
          <div className="col-md-12 col-lg-12 vh-10">
            <span className="section-id blue-color">
              <h3 className='h3-md test2x'> Colborne Dental</h3>
            </span>
            <h3 className=" steelblue-color">
              WE ARE COMMITED TO PROVIDE THE BEST CUSTOMER SERVICES AND CARE
            </h3>
            <div className='para'>
              <p>
                Colborne Dental Office is committed to providing the best
                customer service and care for each of our patients, while
                providing a comfortable environment for every visit. Our expert
                dental team prioritizes the needs of the patients first and
                promises to ensure that those needs and expectations are met.
                Our highly detail-oriented and friendly staff believes that the
                best way to serve our clients is through efficient and honest
                care.
              </p>
              <p>
                We pride ourselves on our excellent service towards the
                community and after 30 years under the ownership of Dr. Luong,
                he has passed the torch onto Dr. Appan. While Dr. Luong is now
                semi-retired, he continues to practice at the clinic on a part
                time basis. Through many years of experience, Dr. Appan leads
                the original dental team of Colborne Dental Office with
                confidence and integrity. The relationship he maintains with his
                clients allows him to concentrate on the personalized care of
                each patient, making sure that their oral health is always his
                primary focus.
              </p>
              <p>
                The entire team at Colborne Dental Office welcomes each patient
                with undivided attention and offers the best dental care within
                Colborne, Ontario.
              </p>
            </div>
          </div>
        </div>
        {/* <Row>
            <Col className='col-md-7x'>

            </Col>
            <Col className='col-md-6x'>

            </Col>
          </Row> */}


        {/* <div className="mt-3 row">
          <div className='col-md-6'>
            <h3 className="p-3 text-xl steelblue-color">
              Book your appointment with Colborne Dental today!
            </h3>

          </div>
          <div className="p-2 col-md-6">
            <button
              type="button"
              onClick={() => navigate("/appointment")}
              className="content-center self-center btn place-self-center btn-md btn-blue blue-hover submit"
            >
              Book Appointment
            </button>
          </div>
        </div> */}

      </section>
      {/* </Container> */}
    </>
  )
}
