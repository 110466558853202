import React from "react";

export default function DentalHygieneServices() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Dental Hygiene Services</h1>
        <div className="flex flex-wrap w-4/5 gap-2 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/DentalHygieneServices/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
          <img
            src="imagesx/colborne/services/DentalHygieneServices/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
          <img
            src="imagesx/colborne/services/DentalHygieneServices/3.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div className="mb-10">
          <p className="py-1 para">
            A consistent daily regimen that includes brushing and flossing, is
            the key to maintaining good dental hygiene. Additionally, visiting
            your dentist for routine check-ups and cleanings, while avoiding
            eating sugary snacks as often as possible also greatly contributes
            to your oral health.
          </p>
          <div>
            <p className="py-1 para">
              At Colborne Dental office, we recommend that you come in for a
              dental checkup every six months in order to ensure that you
              haven’t begun to develop any symptoms or undetectable issues. We
              address and treat any complications that we see in order to
              prevent those symptoms from worsening.
            </p>
            <ul className="px-20 list-disc list-inside">
              <li>
                <span className="font-semibold">Fluoride Treatments: </span>{" "}
                Used to keep your enamel strong and helps your teeth fight
                against decay or future cavities. Fluoride treatments are much
                more effective at protecting your teeth than any
                over-the-counter dental products.
              </li>
              <li>
                <span className="font-semibold"> Gum Therapy:</span> Gum therapy
                uses a combination of curettage, root-planing, and local
                medication, to help fight and eliminate gum disease.
              </li>
              <li>
                <span className="font-semibold">
                  Professional Breath Freshening Treatments:
                </span>{" "}
                If you suffer from chronic halitosis (bad breath), our breath
                freshening services are even able to treat those with the most
                severe cases of bad breath.
              </li>
            </ul>
          </div>
          <p className="py-1 para">
            Whether you need a simple checkup, to cure gum disease, or to
            freshen your breath, Colborne Dental Office can find a solution to
            improve your dental hygiene. You can count on Dr. Appan and his team
            to suggest the best dental regime for daily care, tailored to your
            specific needs.
          </p>
        </div>
      </div>
    </>
  );
}
