import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { teamInfo } from "../../assets/data/teamnew";
import './styles.css';

// import Zoom from 'react-medium-image-zoom';
// import 'react-medium-image-zoom/dist/styles.css';
// import Imgix, { ImgixProvider } from "react-imgix";

export default function TeamProfileFull({ home }) {

    const [ open, setOpen ] = useState(false);


    function ShowMe(index) {
        // console.info("Tag value");
        // console.log(open === index ? null : index);
        setOpen(open === index ? null : index);
    }

    return (
        <>
            {/* <Container>  */}
            <section id="doctors-3" className="mt-5 row doctors-section division">
                <span className="section-id blue-color">
                    <h3 className='h3-md test2x'> Our Team</h3>
                </span>
                <div className="container">

                    <div className="row">
                        {teamInfo.dentist.map((x, key) => {
                            return (


                                <div className="col-md-6 col-lg-4 vh-10 " key={key}>
                                    <div className="rounded doctor-1">
                                        {/* Doctor Photo */}


                                        <Image
                                            className="aboutus-image imgTeam"
                                            src={"/imagesx/colborne/docs/webp/" + x.filename + ".webp"}


                                            alt={x.name}
                                            sizes="100vw"
                                        />

                                        <div className="doctor-meta">
                                            <h5 className="h5-xs blue-color">{x.name}</h5>
                                            <span>{x.title}</span>

                                            <Button
                                                onClick={() => ShowMe(key)}
                                                aria-controls={x.name}
                                                aria-expanded={open}
                                                variant="link"
                                                className="viewmore mt-15"
                                                title=""
                                                style={{ textDecoration: 'none' }}
                                            >
                                                View More Info
                                            </Button>

                                            {open === key && (
                                                <div id={x.name} className="mt-2">
                                                    <span className="text-sm text-left">
                                                        {x.overview}
                                                    </span>
                                                </div>
                                            )}


                                        </div>
                                    </div>{" "}
                                </div>
                            );
                        })}
                    </div>

                </div>
            </section>
            {/* </Container> */}
        </>
    );
}
