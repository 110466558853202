import MiniMarkerHeader from "components/Header/MiniMarkerHeader";
import React from "react";
import { Dropdown, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BrowserView } from "react-device-detect";
import { HashLink } from "react-router-hash-link";
import { oservices } from "assets/data/ourservices";

const MenuHeader = () => {
  return (
    <>
      <BrowserView>
        <MiniMarkerHeader />
      </BrowserView>
      <Navbar
        collapseOnSelect={true}
        bg="black"
        variant="dark"
        expand="lg"
        fixed={"top"}
        className={"position-sticky ps-0 pt-0"}
      >
        {/* <Container> */}
        <Navbar.Text>
          <div className="desktoplogox">
            {/* <Nav.Link className="p-0" eventkey="1" to="/"> */}
            <div className="space-x-2 d-flex">
              <img
                src="imagesx/webp/cdclogo.webp"
                className="w-20 mt-1 ml-5 cdclogo h-25"
                alt="header-logo"
              />
              <div className="space-x-0 row">
                <div className="col col-lg-6">
                  <Link to="/">

                    <div className="space-x-1 d-flex">
                      <h4 className="mt-2 text-3xl font-bold text-primary h4-sm brandTypography">
                        Colborne
                      </h4>
                      <h4 className="mt-2 text-3xl font-bold text-primary h4-sm brandTypography">
                        Family
                      </h4>
                    </div>

                  </Link>
                </div>
                <div className="mt-2 col col-lg-6">
                  <Link to="/" >
                    <span className="ml-2 text-4xl font-bold text-white cdclogoFont h4-sm font-Poppins">
                      DENTAL
                    </span>
                  </Link>
                  {/* <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    className="justify-end float-right text-right"
                  /> */}
                </div>
              </div>
            </div>
            {/* </Nav.Link> */}
          </div>
        </Navbar.Text>

        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="order-1 mx-auto order-md-0"
        >
          <Nav className="float-right ml-20">
            <Navbar.Text>
              <Link to="/" eventkey="Home">
                {" "}
                <span className="wsarrow menuLinkItem">Home </span>
              </Link>
            </Navbar.Text>
            {/* <Nav.Link eventkey="1" as={Link} to="/">
              <span className="wsarrow menuLinkItem"> Home </span>
            </Nav.Link> */}

            <NavDropdown
              title={<span className="menuLinkItem">Patients </span>}
              id="collasible-nav-dropdown-patients"
              renderMenuOnMount={true}
            >
              <Dropdown as={NavItem}>
                <NavDropdown.Item as={Link} to="/newpatient" key="1" eventkey={"NewPatient"} >New Patient</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/fileupload" key="2" eventkey={"UploadForms"} >Upload Forms</NavDropdown.Item> */}

                <NavDropdown.Item as={Link} to="/locateus" key="3" eventkey={"LocateUs"} > Locate Us</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/finance" key="4" eventkey={"FinancingOptions"} >Financing Options</NavDropdown.Item>

              </Dropdown>

            </NavDropdown>
            {/* <Nav.Link

              as={Link}
              to="/services"
              className="text-white menuLinkSubItem wsarrow menuLinkItem"
              eventkey="services"
            >
              Services
            </Nav.Link> */}

            <NavDropdown
              title={<span className="menuLinkItem">Services </span>}
              id="collasible-nav-dropdown-services"
              renderMenuOnMount={true}
            >
              <Dropdown as={NavItem}>
                {
                  oservices.map((x, key) => {
                    return (
                      <NavDropdown.Item as={Link} state={{ toShowItem: x.value }} to="/services" key={key} >{x.name}</NavDropdown.Item>

                    )
                  })
                }

              </Dropdown>

            </NavDropdown>

            <Nav.Link

              as={Link}
              to="/aboutus"
              className="text-white menuLinkSubItem wsarrow menuLinkItem"
              eventkey="aboutus"
            >
              About Us
            </Nav.Link>
            {/* <Nav.Link
              eventkey="8"
              as={HashLink}
              to={"/#blogs"}
              className="text-white menuLinkSubItem wsarrow menuLinkItem"
            >
              Blogs
            </Nav.Link> */}

            {/* <NavDropdown
              title={<span className="menuLinkItem">Blogs </span>}
              id="collasible-nav-dropdown-patients"
              renderMenuOnMount={true}
            >
              <Dropdown as={NavItem}>
                <NavDropdown.Item as={Link} to="/newpatient" key="1" eventkey={"NewPatient"} >What to look for when choosing a dental clinic</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/fileupload" key="2" eventkey={"UploadForms"} >Upload Forms</NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/locateus" key="3" eventkey={"LocateUs"} > Locate Us</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/finance" key="4" eventkey={"FinancingOptions"} >Financing Options</NavDropdown.Item>

              </Dropdown>

            </NavDropdown> */}
            <Nav.Link
              eventkey="18"
              as={HashLink}
              to={"/blog"}
              className="text-white menuLinkSubItem wsarrow menuLinkItem"
            >
              Blog
            </Nav.Link>
            <Nav.Link
              eventkey="contactuskey"
              as={HashLink}
              to={"/#contactus"}
              className="text-white menuLinkSubItem wsarrow menuLinkItem"
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              eventkey="38"
              as={Link}
              to="/appointment"
              className="text-white menuLinkSubItem wsarrow menuLinkItem"
            >
              <div className="float-right mr-sm-2 ">
                <button
                  type="submit"
                  className=" btn btn-large bg-primary blue-hover submit ml-9"
                >
                  <div className="space-x-3 d-flex">
                    <i className="text-white bi bi-calendar-check"></i>

                    <span className="text-sm font-semibold text-white">
                      REQUEST APPOINTMENT
                    </span>
                  </div>
                </button>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default MenuHeader;
