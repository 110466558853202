import React from 'react'
import { Helmet } from 'react-helmet-async';

function HelmetTag({ title, desc, children, ...props }) {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{title}</title>
                <meta name="description" content={desc} />
            </Helmet>
            {children}
        </>
    )
}


function HelmetMeta({ meta, ...props }) {
    return (
        <Helmet>
            {
                meta.map((m) => {
                    return (

                        <meta name={m.name} content={m.content} />
                    )
                })}

        </Helmet>
    )
}

export { HelmetTag, HelmetMeta };