import BreadCrumbCDC from "components/Breadcrumbs/NewBreadCrumb";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import { BrowserRouter as Link } from "react-router-dom";


export default function BlogsIndex() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const baseUrl = "imagesx/blogs/";

    const blogs = [
        {
            Id: 1,
            img: baseUrl + "surclinic.jpeg",
            imgcss: "h-36",
            link: "cs",
            bg: "bg-gradient-to-r from-gray-300 to-gray-100",
            title: "How to choose the right dental clinic",
            desc: "Selecting an appropriate dental clinic is imperative for maintaining optimal oral health",
        },

        {
            Id: 3,
            img: baseUrl + "pdc.png",
            imgcss: "h-36",
            link: "pd",
            bg: "bg-gradient-to-r from-gray-200 to-gray-100",
            title: "Periodontal Disease: Recognizing the signs and seeking treatment",
            desc: " Understanding periodontal disease: Stages and Signs",
        },
        {
            id: 4,
            img: baseUrl + "clinic.png",
            imgcss: "h-36",
            link: "pdc",
            bg: "bg-gradient-to-r from-gray-100 to-gray-100",
            title: "Preventive Dental Care: The foundation of lifelong Oral Health",
            desc: "Proactive oral care is the linchpin for preserving optimal dental health",
        },
        {
            id: 5,
            img: baseUrl + "td.jpeg",
            imgcss: "h-36",
            link: "tdc",
            bg: "bg-gradient-to-r from-blue-100 to-gray-100",
            title: "Tooth decay and cavities: causes, symptoms, and prevention",
            desc: "Tooth decay is a prevalent oral health concern that can lead to the formation of cavities , if left untreated.",
        },
        {
            id: 6,
            img: baseUrl + "tbh.png",
            imgcss: "h-24 pt-2",
            link: "tb",
            bg: " bg-gradient-to-r from-gray-100 to-gray-200",
            title: " How to Choose the Right Toothbrush: A Comprehensive Guide",
            desc: "Maintaining good oral hygiene is essential for a healthy, beautiful smile. One of the most important tools for achieving this is the toothbrush ",
        },
        {
            Id: 2,
            img: baseUrl + "mw.png",
            imgcss: "h-36",
            link: "mw",
            bg: " bg-gradient-to-r from-gray-200 to-gray-100",
            title: "Should I use a mouthwash?",
            desc: "Mouthwash: Exploring it's role in Oral Care",
        },
    ];

    return (
        <>
            <div className="px-4 mx-auto my-12 md:px-6">
                <BreadCrumbCDC title="Blog"> </BreadCrumbCDC>
                <div className="justify-center text-center">
                    <span className="font-light h1 steelblue-color font-lato">Blog</span>
                </div>
                <div className="flex flex-wrap justify-center -mx-1 space-x-3 lg:-mx-6">
                    {Object.keys(blogs).map((idx) => {
                        return (
                            <div
                                key={idx}
                                className="w-full mt-3 mb-0 ml-2 space-x-2 overflow-hidden rounded-lg card md:w-1/2 lg:w-1/4"
                            >
                                <Link to={"/" + blogs[ idx ].link}>
                                    {/* <div className="w-full px-1 my-1 mt-4 overflow-hidden rounded-lg shadow-lg md:w-1/2 lg:w-1/4"> */}
                                    <div className={blogs[ idx ].bg + " h-36"}>
                                        <img
                                            className={blogs[ idx ].imgcss + " rounded-t-lg"}
                                            src={blogs[ idx ].img}
                                            alt=""
                                        />
                                    </div>
                                    <div className="p-2 ">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                            {blogs[ idx ].title}
                                        </h5>

                                        <p className="mb-3 text-sm text-gray-700 align-text-bottom dark:text-gray-400">
                                            {blogs[ idx ].desc}
                                        </p>

                                        <div className="float-right">
                                            <div
                                                href="#"
                                                className="inline-flex items-center px-3 py-2 mb-2 text-sm font-medium text-center text-white bg-blue-400 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                            >
                                                Read more
                                                <svg
                                                    aria-hidden="true"
                                                    className="w-4 h-4 ml-2 -mr-1"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
