import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styles from './style.css';

const ButtonGroup = ({
    buttons,
    orientation,
    buttonClassName,
    containerClassName,
    activeButtonClassName,
    onButtonClick
}) => {
    const [clickedId, setClickedId] = useState(-1);

    const activeClass = activeButtonClassName || `${styles.activeTimeChip}`;
    const buttonClass = buttonClassName || `${styles.customButton}`;
    const containerClass =
        containerClassName ||
        (orientation === 'vertical' ? `${styles.vertical}` : `${styles.container}`);

    return (
        <div className={containerClass}>
            {buttons.map((e, i) => (
                <Button
                    key={i}
                    name={e}
                    onClick={(event) => {
                        setClickedId(i);
                        onButtonClick(event);
                    }}
                    // variant="secondary"
                    className={
                        i === clickedId ? `${buttonClass} ${activeClass}` : `${buttonClass}`
                    }
                >
                    {e}
                </Button>
            ))}
        </div>
    );
};

ButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.string).isRequired,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    buttonClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    activeButtonClassName: PropTypes.string,
    onButtonClick: PropTypes.func
};

ButtonGroup.defaultProps = {
    orientation: 'horizontal'
};

export default ButtonGroup;