import ContactFormNew from "./ContactFormNew";
import SideBar from "./SideBar"; //SendContactUsEnquiry

import "./styles.css";

const Contact = () => {
  return (
    <>
      {/* <HelmetTag title={CONTACTUS.title} desc={CONTACTUS.description} /> */}

      <div className="container md:mt-4">
        {/* <BreadCrumbCDC title="Contact us" /> */}
        {/* Start */}

        <div className="text-center">
          <span className="h3 titleSubHeadert ext-center steelblue-color">
            Have a Question? Get In Touch
          </span>
        </div>
        {/* Side Bar */}
        <div className="flex flex-col justify-center gap-5 mt-4 sm:flex-row ">
          <div className="">
            <SideBar />
          </div>
          <div className="md:mt-10">
            <ContactFormNew />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
