import BreadCrumbCDC from "components/Breadcrumbs/NewBreadCrumb";
import ScrollButton from "components/ScrollTop/ScrollButton";
import ServicesMob from "Mobile/components/ServicesMob";
import { useEffect } from "react";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import { InvisalignInvisibleBraces } from "./ServiceItems/InvisalignInvisibleBraces";
import { TeethWhitening } from "./ServiceItems/TeethWhitening";
import { WisdomTeethExtraction } from "./ServiceItems/WisdomTeethExtraction";
import ServicesDesktop from "./ServicesDesktop";
import "./styles.css";

//ToDo https://dev.to/salehmubashar/search-bar-in-react-js-545l
export default function OurServices() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div id="apt" className="container h-full">
                <BreadCrumbCDC title="Our Services"></BreadCrumbCDC>

                {/* <div className="row">
                    <h3 className="text-center h3-md steelblue-color">Our Services</h3>

                    <p className="text-justify para">
                        At Colborne Family Dental Studio, nothing is more important than
                        the highest quality of care. From state-of-the-art equipment to
                        regular doctor training, our team remains at the forefront of
                        modern dentistry. We incorporate the latest medical developments
                        to ensure our patients’ care is fast, painless, and effective.
                    </p>
                </div> */}
                <BrowserView>
                    {/* <WisdomTeethExtraction /> */}
                    <ServicesDesktop />
                </BrowserView>
                <MobileOnlyView>
                    <ServicesMob />¸
                </MobileOnlyView>
            </div>
            <ScrollButton />
        </>
    );
}
