import GetDirectionsBox from "components/Header/GetDirectionsBox";
import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Hero = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    // <div className="py-20" id="hero-12">
    //   <div className="container px-6 mx-auto">
    //     <span className="mb-2 text-2xl font-semibold uppercase steelblue-color">
    //       Welcome To Colborne Family Dental
    //     </span>
    //     <h1 className="py-4 font-bold text-gray-700 h1">
    //       WE CARE FOR YOUR SMILE
    //     </h1>
    //     <p className="text-2xl p-lgx">Family Dentist in Colborne, Ontario</p>
    //   </div>
    //   <div className="py-8 ml-10 space-x-2 d-flex">
    //     <Link to="/newpatient">
    //       <button className="inline-flex items-center px-4 py-2 space-x-2 font-bold text-white rounded btn bg-blue blue-hover">
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="h-8 text-center text-white w-7"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //           strokeWidth="2"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
    //           />
    //         </svg>{" "}
    //         <span className="text-2xl"> 905-355-1419</span>
    //       </button>
    //     </Link>
    //     <Link to="/newpatient">
    //       <button className="inline-flex items-center px-4 py-2 space-x-2 font-bold text-white rounded btn bg-blue blue-hover">
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="h-8 text-white w-7"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //           strokeWidth="1"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
    //           />
    //         </svg>
    //         <span className="text-2xl"> New Patient</span>
    //       </button>
    //     </Link>
    //   </div>
    //   <div className="px-10 py-2 mx-auto">
    //     <GetDirectionsBox
    //       layoutcss={"space-x-2"}
    //       name="desktopDirectionsMob"
    //       inputcss={"bg-gray-200"}
    //       btncss={"btn h-8  btn-outline-secondary btn-sm mt-3"}
    //     />
    //   </div>
    // </div>
    <Carousel
      // className="carousalSlider"
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      // containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass="imgSlider"
      keyBoardControl
      // minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      rewind={false}
      rewindWithAnimation={true}
      rtl={false}
      shouldResetAutoplay
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
      autoPlay
    >
      <div id="hero-12" className="py-20">
        <div className="container px-6 mx-auto">
          <span className="mb-2 text-2xl font-semibold uppercase steelblue-color">
            Welcome To Colborne Family Dental
          </span>
          <h1 className="py-4 font-bold text-gray-700 h1">
            WE CARE FOR YOUR SMILE
          </h1>
          <p className="text-2xl p-lgx">Family Dentist in Colborne, Ontario</p>
        </div>
        <div className="py-8 ml-10 space-x-2 d-flex">
          <Link to="/newpatient">
            <button className="inline-flex items-center px-4 py-2 space-x-2 font-bold text-white rounded btn bg-blue blue-hover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 text-center text-white w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>{" "}
              <span className="text-2xl"> 905-355-1419</span>
            </button>
          </Link>
          <Link to="/newpatient">
            <button className="inline-flex items-center px-4 py-2 space-x-2 font-bold text-white rounded btn bg-blue blue-hover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 text-white w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              <span className="text-2xl"> New Patient</span>
            </button>
          </Link>
        </div>
        <div className="px-10 py-2 mx-auto">
          <GetDirectionsBox
            layoutcss={"space-x-2"}
            name="desktopDirectionsMob"
            inputcss={"bg-gray-200"}
            btncss={"btn h-8  btn-outline-secondary btn-sm mt-3"}
          />
        </div>
      </div>
      <div id="heronew" className="h-full"></div>
    </Carousel>
  );
};
