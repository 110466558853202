import React from "react";

export default function AmalgamFree() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1">Amalgam Free</h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/AmalgamFree/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md "
          />
          <img
            src="imagesx/colborne/services/AmalgamFree/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div className="mb-10">
          <p className="py-1 para">
            Metal fillings,made of amalgam materials, are effective in restoring
            teeth but are not very aesthetically pleasing. They are much more
            noticeable and patients may find it uncomfortable to have mercury or
            other metals implanted in their mouths.{" "}
          </p>
          <p className="py-1 para">
            Amalgam-free fillings are a fantastic alternative to metal fillings
            to treat decaying/decayed teeth and enhances overall oral healt At
            Colborne Dental Office, we offer amalgam-free dentistry treatments,
            without any mercury or metal materials such as bonded fillings
            dental crowns, dental bridges, root canal therapy, non-surgical gum
            therapy, porcelain veneers, and dental implants.
          </p>
          <p className="py-1 para">
            {" "}
            If you have a damaged or loose crown, gum disease, or need a
            filling, our expert dental team can help improve your smile
            customized to your needs to guarantee satisfaction.Those looking for
            efficient, comfortable, and professional dental care, check out
            Colborne Dental office to set up an appointment today. Dr. Appan and
            his helpful team in Colborne Ontario can help enhance your smile.
          </p>
        </div>
      </div>
    </>
  );
}
