export const oservices = [
    {
        name: "Dental Implants",
        value: "DentalImplants",
        images: [],
        list: ["S",
            "have sufficient amounts of bone tissue in your jawbone",
            "do not have any other oral health complications are unable do not prefer to wear dentures want to enhance their speech"],
        desc: "Dental implants help to restore a missing tooth/teeth with a metal post and a crown attached to fill that space to blend in with the natural appearance of the rest of the teeth. Dental implants allow for an artificial tooth to replace that missing gap, when a dental bridge or dentures aren’t a suitable resolution.While it will take several months for the dental implant to properly fuse with the tissues inside your jaw, once the implant has bonded, it will function like your natural teeth. Sometimes dentures can shift and slip out of place, but implant surgery secures the articificial teeth into your mouth. Since dental implants are typically made of strong titanium materials, they can last longer than your natural, real teeth. Additionally, an implant replaces the missing tooth’s natural root, which means that it helps to strengthen neighbouring teeth and prevents any more teeth from shifting over time. Dental implants are recommended to those who: \n Dental implants can be a very long procedure, typically requiring multiple visits and surgeries to complete the process. Contact Colborne Dental Office to have Dr. Appan conduct an examination to determine if dental implants are a viable solution for you."
    },
    {
        name: "Dentures",
        value: "Dentures",
    },
    {
        name: "Amalgam Free",
        value: "AmalgamFree",
        images: [1, 2],
        desc: " Metal fillings,made of amalgam materials, are effective in restoring teeth but are not very aesthetically pleasing. They are much more noticeable and patients may find it uncomfortable to have mercury or other metals implanted in their mouths. Amalgam-free fillings are a fantastic alternative to metal fillings to treat decaying/decayed teeth and enhances overall oral healt At Colborne Dental Office, we offer amalgam-free dentistry treatments, without any mercury or metal materials such as bonded fillings dental crowns, dental bridges, root canal therapy, non-surgical gum therapy, porcelain veneers, and dental implants. If you have a damaged or loose crown, gum disease, or need a filling, our expert dental team can help improve your smile customized to your needs to guarantee satisfaction.Those looking for efficient, comfortable, and professional dental care, check out Colborne Dental office to set up an appointment today. Dr. Appan and his helpful team in Colborne Ontario can help enhance your smile.",
    },
    {
        name: "Bonded Fillings",
        value: "BondedFillings",
        images: [1, 2],
        desc: "Metal fillings used to be the only way to treat cavities and decayed teeth, which is an effective solution, however, it can be uncomfortable to have metal bonded to your teeth. Additionally, they distinctly stand out from the colour of your teeth. Bonded fillings are a natural-looking alternative made of composite resin materials. Tooth-coloured bonded fillings offer a strong, durable form of dental restoration that protects your teeth. The resin can be customized to match the exact colour and shade of anyone’s smile. A special UV light is used to create a durable bond and the composite resin has been molded to adapt to the tooth and appear natural. When a patient is getting a bonded filling, the damaged tooth (or teeth) are first cleaned and dried. Afterwards, the decayed part of the tooth is removed, and replaced by resin. Once the bond is applied, the tooth/teeth are buffed to shine and give a polished finish. As a popular choice for dental restoration, bonded fillings can be conveniently completed within one appointment."
    },
    // {
    //     name: "Dental Bridges",
    //     images: [1],
    //     desc: "A dental bridge connects a gap between teeth with artificial, but natural looking teeth. Missing teeth can cause issues chewing, eating, or speaking and dental bridges can be an ideal solution to resolve these problems. Dental bridges are semi-permanent and must only be removed by your dentist."
    // },
    // {
    //     name: "Dental Bridges",
    //     images: [1],
    //     desc: "A dental bridge connects a gap between teeth with artificial, but natural looking teeth. Missing teeth can cause issues chewing, eating, or speaking and dental bridges can be an ideal solution to resolve these problems. Dental bridges are semi-permanent and must only be removed by your dentist."
    // },
    {
        name: "Dental Crowns",
        value: "DentalCrowns",
        images: [1, 2, 3],
        desc: "Dental Crowns, also known as dental caps, are one of the most popular ways to treat severely decayed, damaged, and worn down teeth for both aesthetic and restorative purposes. Dental crowns improve the appearance of stained, cracked and/or chipped teeth because of their high durability and protection.Unlike other forms of dental restorations, dental crowns cover the tooth completely, right up to the gumline. When a crowns is placed over a tooth, it acts as its new exterior protection. Dental crowns are highly recommended for: Replacing a large cavity Strengthening a weakened tooth Repairing broken teeth Covering a tooth with a root canal Covering misshaped or stained tooth Covering a dental implant Dental crowns can be made from a great number of materials, ranging from gold alloy to ceramic, from porcelain to metal, your dentist will prescribe the most suitable type for you. Getting a dental crown fitted will require two appointment visits; the first will allow one of the Colborne dental specialists check to make sure your tooth can support the crown. An impression is then made of that tooth and sent to a lab that will create your permanent crown. A temporary crown will be placed on your tooth between visits and replaced by the new one once it is ready during the follow-up visit."
    },
    {
        name: "Dental Hygiene Services",
        value: "DentalHygieneServices",
        images: [1, 2, 3],
        desc: "A consistent daily regimen that includes brushing and flossing, is the key to maintaining good dental hygiene. Additionally, visiting your dentist for routine check-ups and cleanings, while avoiding eating sugary snacks as often as possible also greatly contributes to your oral health. At Colborne Dental office, we recommend that you come in for a dental checkup every six months in order to ensure that you haven’t begun to develop any symptoms or undetectable issues. We address and treat any complications that we see in order to prevent those symptoms from worsening. Fluoride Treatments: Used to keep your enamel strong and helps your teeth fight against decay or future cavities. Fluoride treatments are much more effective at protecting your teeth than any over-the-counter dental products.Gum Therapy: Gum therapy uses a combination of curettage, root-planing, and local medication, to help fight and eliminate gum disease.Professional Breath Freshening Treatments: If you suffer from chronic halitosis (bad breath), our breath freshening services are even able to treat those with the most severe cases of bad breath. Whether you need a simple checkup, to cure gum disease, or to freshen your breath, Colborne Dental Office can find a solution to improve your dental hygiene. You can count on Dr. Appan and his team to suggest the best dental regime for daily care, tailored to your specific needs."
    },

    {
        name: "Full Mouth Restoration",
        value: "FullMouthRestoration",
        images: [1, 2],
        list: ["Missing teeth due to decay or an external injury", "Fractured and cracked teeth", "Heavily damaged, highly eroded teeth", "Chronic biting issues (ie. pain, difficulty speaking or chewing)"],
        desc: "Certain medical cases will require full reconstruction, rebuilding both the upper and lower set of teeth to restore a healthy smile. Dr. Appan and the Colborne Dental staff all have extensive years of dental experience to help make a treatment plan customized to your unique situation. Be confident in your smile and allow our friendly team to work on a full mouth restoration plan for you.Those needing a full mouth restoration will have symptoms such as: \n atients may suffer from one or more of these symptoms and may even have complications the require a procedure to rebuild the whole set of teeth, bite, and gums. Dr. Appan will recommend the best way to restore your teeth – whether it will be a combination or group of options including: dental crowns/bridges, porcelain veneers, (non-surgical) gum therapy, root canal therapy, and dental implants. A treatment plan will outline the exact procedure and long term steps towards a full recovery and new smile!"
    },
    {
        name: "Implant Supported Dentures",
        value: "ImplantSupportedDentures",
        images: [],
        desc: "Conventional dentures temporarily sit on your gums, allowing them to be easily moved or loosened whenever you move your mouth. Implant Supported Dentures avoid this major setback, as they are permanently imbedded to implants inside your jaw. Implant supported dentures can be used for both the upper and lower jaw, but are more frequently made for the lower jaw because conventional dentures tend to fit better on the upper jaw.The gum tissues around the implants will naturally fuse with the dentures and the healing process can typically take around 3-6 months (healing time varies from patient to patient). Afterwards, additional surgeries will be required to expose the tops of the implants, in order to imbed the dentures. Implanted dentures need to be removed on a daily basis for routine cleaning and brushing, the same way that you would with traditional dentures. Our team at Colborne Dental Office will find the right dentures that are the most suitable fit for your circumstance and that you find the most comfortable. Contact us today to work on an effective treatment plan for your implant supported dentures."
    },
    {
        name: "Invisalign Invisible Braces",
        value: "InvisalignInvisibleBraces",
        images: [1, 2],
        desc: "Feeling self conscious about your smile? Invisalign Invisible braces are the best solution to help straighten your teeth or fix overcrowding. While traditional metal braces are directly bonded to your teeth, Invisalign invisible braces uses aligners that can be removed when eating, brushing, and flossing. Unlike obvious looking metal braces, the Invisalign aligners are made of high quality, durable clear plastic making them barely noticeable. Feel more confident about showing off your teeth without the worry of others seeing your aligners."
        , questions: [{
            q: "How does Invisalign work?",
            a: "The process of having Invisalign Invisible braces will require the patient to wear a set of aligners for at least 21 hours per day, for 2 week periods at a time. Aligners must be worn at all times, but they can be removed when brushing, flossing, or eating. Invisalign braces need to have contact with the teeth for a required amount of time in order to show results. The number of aligners that will need to be worn is dependent on the severity of the misalignment. This treatment process will likely last for one year, with periodical visits to our dental office to monitor the progress."
        },
        {
            q: "How are the Invisalign Aligners made?",
            a: "The Invisalign Aligners are custom made to fit personally to your teeth. Our dental team will create impressions of your teeth during the checkup. After that, the impressions will be sent to a dental lab to make the aligners. Once ready for pickup, our knowledgeable staff will instruct you on the daily use and care to ensure that you’ll receive effective treatment with friendly service. Contact Colborne Dental Office to setup an appointment with Dr. Appan in Trenton, Ontario."
        }
        ]
    },
    {
        name: "Non-Surgical Gum Therapy",
        value: "NonSurgicalGumTherapy",
        images: [],
        desc: "One of the most common oral health complications that patients come in for is gum disease. Early signs of gum disease may be undetectable and evolves into gingivitis; signs may include bad breath, inflamed and sensitive gums. As the symptoms progress, they can become more painful and may even lead to severe damage such as gym recession and tooth loss.\n Regular dental visits can allow the dentist to check for any beginning detections of gingivitis or gum disease. With the latest in dental technology, we use digital x-rays to monitor the state of your gums to ensure that we catch any early signs of gum disease and help you restore the state of your oral health. Consistent and regimented flossing can typically fight off further complications, if found during the gingivitis stage. However, once the disease has progressed into periodontal disease, our dental specialists will need to get rid of excess plaque build-up and treat any infections below the gumline.\n Through local medication, curettage, root-planing, and preventative medicine will be used to help fight the chronic infection and restrict the disease from spreading. Curettage helps to eliminate the source of infection by removing the soft tissues surrounding the tooth, allowing that part of your gum to heal. Root-planing is a technique used to smooth out the infected root’s surface, furthering the healing process for your gums.Each patient recovers at a different pace, which is why we recommend that multiple follow up visits are required to allow our dental team to monitor the healing progress. If you are starting to show any early signs of gingivitis or gum disease, contact Colborne Dental Office to schedule an appointment and treat it as soon as possible."
    },
    {
        name: "Porcelain Veneers",
        value: "PorcelainVeneers",
        images: [1, 2, 3],
        desc: "Porcelain veneers are a type of cosmetic dental restoration that helps to make your smile shine brighter and whiter. Immediately fix chipped teeth, cover up heavy stains, or close gaps between teeth with porcelain veneers. Cosmetic procedures are a permanent process that cannot be reversed, so it’s vital to be certain before making an informed decision.\n Porcelain veneers are bonded to your natural teeth’s enamel, and can be coloured to match the shade of your other teeth, while providing strength and protection. Unlike dental crowns or Invisalign Invisible braces, veneers are less obstructive and can easily correct minor imperfections such as a stained or chipped tooth. Porcelain is used as the primary material for dental veneers because of its colour and durability. With proper care and routine checkups, porcelain veneers are a fantastic, long term solution that doesn’t require an orthodontic procedure or treatment.During the first appointment, the dentist will examine the tooth, clean it, and remove a tiny amount of enamel to make room for the veneer. Once an impression is taken and sent to a dental lab, a temporary veneer will be placed until the permanent one has been created. Once ready, the veneer will be securely bonded to the tooth, and then polished afterward.\n Speak with any one of our dental specialists, as our knowledgeable staff can help you book a visit and find out if porcelain veneers are the best solution for you."
    },

    {
        name: "Root Canal Therapy",
        value: "RootCanalTherapy",
        images: [1, 2],
        desc: "When the inner tissues of a tooth are decayed, root canal therapy is required to allow the dentist to extract the afflicted root/dental pulp without removing the tooth entirely. The dental pulp refers to the many blood vessels, nerves, and inner tissues that extend from the crown right down to the jawbone, that help keep the tooth healthy. Caused by either infection or external injury, bacteria enters inside the tooth and then deteriorates the tooth resulting in pain, swelling, and damage surrounding that tooth. Root canal therapy is the ideal treatment to avoid the complication from worsening or spreading to other teeth.\n By removing the dead tissues and dental pulp, it will alleviate the pain and discomfort caused by the infected tooth. Depending on the severity of damaged tooth and dental pulp, a root canal treatment may take more than one appointment to cure. The inner pulp chamber must be thoroughly cleaned, the cavity must be resealed, and then a dental crown is placed on top to protect the tooth from further infection. Proper care and a good dental hygiene regimen is required to improve your oral health after a root canal therapy procedure."
    },
    {
        name: "Teeth Whitening",
        value: "TeethWhitening",
        images: [1, 2],
        questions: [
            {
                q: "Home Whitening Kits:", a: "Get a personalized home whitening kit created especially for you by our dental specialists. Our personalized take-home kits will include a bleaching gel and custom-made mouth trays, and take approximately 1-2 weeks to start showing results. Our bleaching gel has a higher intensity and is much more effective than over-the-counter products, helping you achieve a brighter smile."
            }
        ],
        desc: "Whether you take perfect care of your teeth or not, stain-heavy foods and beverages are unavoidable. Through regular wear and tear or even that daily cup of coffee, our teeth start to get discoloured over time and could really benefit from a whiter smile. Start simple with regimented brushing and flossing; then try using home whitening kits such as whitening strips, pens, or mouth trays. These are all great options for mild stains that are caught early, but for those more intense, long lasting discolorations and stains, we can help. Our highly knowledgeable dental team offers a variety of different teeth whitening solutions, including:"
    },
    {
        name: "Wisdom Teeth Extraction",
        value: "WisdomTeethExtraction",
        images: [1, 2],
        desc: "Teeth extractions are necessary when a tooth is too decayed to restore through bonding/crowns, because of overcrowding, or to make room for orthodontic treatments. One of the most common reasons for a dental extraction is for wisdom teeth. Located at the back of both the upper and lower jaw, wisdom teeth start to grow and typically appear in our late teen years or early 20s. While each patient’s case may vary, wisdom teeth can cause issues such as pain, inflammation, and infection, for the surrounding teeth. Wisdom teeth may also cause pain and swelling in the gums, as well as increasing the chances of cysts to develop.\n Everyone’s wisdom teeth will grow in differently, making it important for our dental team to monitor its impact on other teeth and how many you have. People commonly have between 1-4 wisdom teeth that grow in; however, there are rare cases in which people do not have any wisdom teeth at all, or even circumstances when the patient has more than four.\n Depending on the way the wisdom tooth/teeth are growing in, they will not need to be removed if they are not making a negative impact on the other teeth and your jaw can accommodate them. Your dentist will assess the state and long term effects of your wisdom teeth through digital x-rays and examinations.\n rior to the procedure of your wisdom teeth extraction, you will be given an anesthesia to ensure you feel comfortable during the surgery. Contact Colborne Dental to schedule a visit and evaluate the status of your wisdom teeth."
    },

];