import React, { useEffect } from "react";

import BreadCrumbCDC from "components/Breadcrumbs/NewBreadCrumb";

export default function ToothDecay() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="prose ">
            <BreadCrumbCDC title="Blog" name="Tooth Decay">
                {" "}
            </BreadCrumbCDC>
            <main className="blog">
                <div className="flex justify-between max-w-screen-xl px-4 mx-auto ">
                    <article className="w-full">
                        <header className="mb-4 lg:mb-6 not-format">
                            <h1 className="blog-Title">
                                {" "}
                                Tooth decay and cavities: causes, symptoms, and prevention{" "}
                            </h1>
                        </header>
                        <div>
                            <p className=" blog-Intro">
                                Tooth decay is a prevalent oral health concern that can lead to
                                the formation of cavities and, if left untreated, more severe
                                dental issues. In this blog post, we'll delve into the causes of
                                tooth decay, how to recognize the symptoms, and ways to prevent
                                cavities from developing. Let's explore how to maintain a
                                healthy smile.
                            </p>
                        </div>
                        <div className="blog-content">
                            <h1 className="blog-subheader">
                                Understanding tooth decay and cavities
                            </h1>
                            <p className="prose prose-lg">
                                Tooth decay occurs when bacteria in your mouth produce acids
                                that break down the tooth enamel, the hard outer layer of the
                                tooth. Over time, this process leads to the formation of a
                                cavity or hole in the tooth. If not addressed, the cavity can
                                grow larger, affecting deeper layers of the tooth and
                                potentially causing pain, infection, and even tooth loss.
                            </p>
                            <h1 className="blog-subheader">Causes of tooth decay</h1>
                            <p>
                                Tooth decay is primarily caused by the buildup of dental plaque,
                                a sticky film of bacteria that forms on your teeth. The primary
                                factors contributing to plaque buildup include:{" "}
                            </p>
                            <ul className="blog-list">
                                <li>
                                    Poor oral hygiene: Failing to brush and floss regularly can
                                    lead to plaque accumulation.
                                </li>
                                <li>
                                    Diet: Consuming sugary and acidic foods and beverages can
                                    contribute to tooth decay.
                                </li>
                                <li>
                                    Dry mouth: A lack of saliva can make it difficult to wash away
                                    plaque and neutralize acids in the mouth.
                                </li>
                                <li>
                                    Genetics: Some individuals may be more susceptible to tooth
                                    decay due to genetic factors.
                                </li>
                            </ul>
                            <h1 className="blog-subheader">
                                Recognizing the Symptoms of Tooth Decay and Cavities:
                            </h1>
                            <p>
                                In the early stages, tooth decay may not exhibit any noticeable
                                symptoms. However, as the decay progresses, you may experience
                                some of the following signs:
                            </p>
                            <ul className="blog-list">
                                <li>
                                    Tooth sensitivity or pain when consuming hot, cold, or sweet
                                    foods and beverages
                                </li>
                                <li>Visible holes or pits in the affected tooth</li>
                                <li>
                                    Staining on the tooth's surface, which may appear as white,
                                    brown, or black spots
                                </li>
                                <li>Pain when biting down or during regular activities</li>
                            </ul>
                            If you notice any of these symptoms, it's essential to visit your
                            dentist for a thorough examination and appropriate treatment.
                            <h1 className="blog-subheader">
                                Preventing tooth decay and cavities
                            </h1>
                            <p>
                                Preventing tooth decay and cavities is crucial for maintaining a
                                healthy smile. Consider these suggestions to aid in the
                                prevention of dental decay:
                            </p>
                            <ul className="blog-list">
                                <li>
                                    Maintain good oral hygiene: Brush your teeth at least twice a
                                    day using a fluoride toothpaste and a soft-bristled
                                    toothbrush. Make a habit of flossing every day to eliminate
                                    plaque and food debris lodged between your teeth and around
                                    your gums.{" "}
                                </li>
                                <li>
                                    Eat a balanced diet: Limit your consumption of sugary and
                                    acidic foods and beverages, which can contribute to tooth
                                    decay. Rather, choose a nutritionally balanced diet that
                                    includes plenty of fruits, vegetables, whole grains, and dairy
                                    items to supply the necessary nutrients for strong teeth and
                                    gums.
                                </li>
                                <li>
                                    Drink fluoridated water: In many areas of Canada, tap water is
                                    fluoridated, providing an optimal level of fluoride to help
                                    strengthen tooth enamel and prevent cavities. Be sure to drink
                                    tap water if it's available in your area.
                                </li>
                                <li>
                                    Visit your dentist regularly: Schedule dental checkups and
                                    cleanings every six months to ensure your teeth and gums
                                    remain in excellent condition.
                                </li>
                            </ul>
                            <div className="blog-conclusion">
                                <p>
                                    Tooth decay and cavities can have a significant impact on your
                                    oral health if left untreated. By understanding the causes and
                                    symptoms of tooth decay and adopting preventive measures, you
                                    can maintain a healthy, cavity-free smile. Remember, regular
                                    dental visits are essential for early detection and
                                    intervention, ensuring that your teeth remain strong and
                                    healthy throughout your life.
                                </p>
                            </div>
                        </div>
                    </article>
                </div>
            </main>
        </div>
    );
}
