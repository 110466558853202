import React from "react";

export default function DentalCrowns() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Dental Crowns </h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/DentalCrowns/1.jpeg"
            alt="amalagam free"
            className="rounded-md"
          />
          <img
            src="imagesx/colborne/services/DentalCrowns/2.jpeg"
            alt="amalagam free"
            className="rounded-md"
          />
        </div>
        <div className="mb-10">
          <p className="py-1 para">
            Dental Crowns, also known as dental caps, are one of the most
            popular ways to treat severely decayed, damaged, and worn down teeth
            for both aesthetic and restorative purposes. Dental crowns improve
            the appearance of stained, cracked and/or chipped teeth because of
            their high durability and protection.Unlike other forms of dental
            restorations, dental crowns cover the tooth completely, right up to
            the gumline. When a crowns is placed over a tooth, it acts as its
            new exterior protection.
          </p>

          <p className="py-1 para">
            Dental crowns are highly recommended for: Replacing a large cavity
            Strengthening a weakened tooth Repairing broken teeth Covering a
            tooth with a root canal Covering misshaped or stained tooth Covering
            a dental implant .
          </p>
          <p className="py-1 para">
            Dental crowns can be made from a great number of materials, ranging
            from gold alloy to ceramic, from porcelain to metal, your dentist
            will prescribe the most suitable type for you.
          </p>
          <p className="py-1 para">
            Getting a dental crown fitted will require two appointment visits;
            the first will allow one of the Colborne dental specialists check to
            make sure your tooth can support the crown. An impression is then
            made of that tooth and sent to a lab that will create your permanent
            crown. A temporary crown will be placed on your tooth between visits
            and replaced by the new one once it is ready during the follow-up
            visit.
          </p>
        </div>
      </div>
    </>
  );
}
