import LocateUs from 'components/Locateus/LocateUs'
import React from 'react'

export default function SideBar() {
    return (
        <>


            <div className='sidebar'>
                <header className="inline-flexs font-semibold text-gray-700 underline underline-offset-4">
                    {" "}
                    Office Location:
                </header>
                <div className="contact-box mb-2 float-left text-justify mt-2">
                    <h2 className="h5-sm steelblue-color">
                        Colborne Family Dental
                    </h2>
                    <h3 className="text-muted">10 Victoria Square</h3>
                    <h3 className="text-muted">Colborne,ON K0K 1S0</h3>
                    <h3 className="text-muted">Canada</h3>
                    <div className='mt-2'>
                        <p>

                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>1-(905)-355-1419</p>
                        <p className='inline-flex'>

                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-4 w-4 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            <a
                                href="mailto:help@colbornefamilydental.ca"
                                className="text-primary"
                            >
                                info@colbornefamilydental.ca
                            </a>
                        </p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className='p-2'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2869.7659499932934!2d-77.89020568495964!3d44.005564079110826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d675b376a6c92f%3A0xbc9d5b9b0c319cf7!2sColborne%20Family%20Dental!5e0!3m2!1sen!2ssg!4v1652521659530!5m2!1sen!2ssg"
                        width="100%"
                        height="250"
                        border="0"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Colborne Family Dental"

                    ></iframe>
                </div>
            </div>

        </>
    )
}
