import { userreviews } from "assets/data/review";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './styles.css';

export default function Reviews() {
	// const reviews = [
	// 	{ name: "John will", comment: "Review 1", rating: 3 },
	// 	{ name: "Bryan Gate", comment: "Review 2", rating: 4 },
	// 	{ name: "Will bruce", comment: "Review 3", rating: 5 },
	// 	{ name: "Cane Willow", comment: "Review 4", rating: 2 },
	// 	{ name: "Benny Guide", comment: "Review 5", rating: 1 },
	// 	{ name: "Shilpa Test", comment: "Review 6", rating: 3 },
	// 	{ name: "Paul Singh", comment: "Review 7", rating: 5 },

	// ]
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
			slidesToSlide: 3, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			slidesToSlide: 2, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	return (
		<>
			<section className="mt-0 text-center section" id="reviewsax">
				<span className="text-center titleSubHeader steelblue-color">
					What Our Patients Say
				</span>
				<Carousel
					fade
					swipeable={false}
					draggable={false}
					showDots={true}
					responsive={responsive}
					infinite={true}
					autoPlay={true}
					autoPlaySpeed={6000}
					keyBoardControl={true}
					customTransition="all .5"
					transitionDuration={2000}
					className="self-center mt-3"
					containerClass="carousel-container "
					removeArrowOnDeviceType={["tablet", "mobile"]}
					// deviceType={this.props.deviceType}
					dotListClass="custom-dot-list-style"
					itemClass="carousel-item-padding-10-px"
				>
					{userreviews.map((x, key) => {
						return (
							// <Col md={4}>
							<div className="rounded-lg shadow-md review-2 h-4/5 " key={key}>
								<div className="text-center">
									<i className="text-4xl text-gray-400 bi bi-person "></i>
									{/* <svg xmlns="http://www.w3.org/2000/svg" className="justify-center object-center w-8 h-8 text-center text-gray-400 align-middle object-center-top " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
									<path stroke-linecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg> */}
								</div>
								<div className="text-center review-txt">
									{/* Quote */}

									{/* <div className="object-none object-center-top"> */}

									{/* </div> */}
									<div className="justify-center py-2 mb-0 align-bottom bottom-2">
										<div className="mt-3">
											<span className="font-bold text-justify ">{x.name}</span>

											<div>
												<i className="text-yellow-400 bi bi-star-fill"></i>
												<i className="text-yellow-400 bi bi-star-fill"></i>
												<i className="text-yellow-400 bi bi-star-fill"></i>
												<i className="text-yellow-400 bi bi-star-fill"></i>
												<i className="text-yellow-400 bi bi-star-fill"></i>
											</div>
										</div>
									</div>
									<p className="text-sm text-gray-800">{x.review}</p>

								</div>
							</div>
							// </Col>
						);
					})}
				</Carousel>
			</section>
		</>
	);
}
