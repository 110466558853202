import * as Yup from "yup";

export const phoneRegExp = /\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*/;

export const validate = Yup.object().shape(
    {
        name: Yup.string()
            .min(1, "Must be 1 character or more")
            .required("Name is a required field"),
        message: Yup.string()
            .min(10, "Must be 10 character or more")
            .required("Message is a required field"),
        // email: Yup.string()
        //     .email()
        //     .when("phone", {
        //         is: (contactnumber) => !contactnumber || contactnumber.length === 0,
        //         then: Yup.string()
        //             .email()
        //             .required("Email or contact number is required"),
        //         otherwise: Yup.string(),
        //     }),
        // contactnumber: Yup.string()
        //     .matches(phoneRegExp, "Phone number is not valid")
        //     .when("email", {
        //         is: (email) => !email || email.length === 0,
        //         then: Yup.string().required("Contact number or Email is required"),
        //     }),
    },
    []
    // [["email", "contactnumber"]]
);
