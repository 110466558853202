import React from "react";
import { HelmetTag } from "components/HelmetTag";
import { SERVICEIMPLANT} from "assets/helmet";

export default function DentalImplants() {
  return (
    <>
         <HelmetTag title={SERVICEIMPLANT.title} desc={SERVICEIMPLANT.description} />

      <div className="container mb-5">
        <h1 className="text-center h1">Dental Implant in Colborne </h1>

        <p className="para">
          If you have lost your teeth due to cavities, accident or trauma and
          are looking for a natural and real looking alternative when compared
          to dentures or crowns, then dental implants can be ideal for you.
          Dental implant is a surgical procedure where the roots of the tooth
          are replaced with metal screwlike posts, a crown is fixed over this
          screw, this artificial tooth will look and function just like your
          real teeth.
        </p>
        <p className="para">
          A dental implant is a welcome alternative for dentures and bridgework.
          You can rely on our dentist in Colborne to offer quality and trusted
          dental implant solutions, our dental solutions are affordable,
          hassle-free. To book an appointment with us, please get in touch with
          our team now, we will be happy to help you with all your requirements.
        </p>
        <div className="mt-2 ml-4">
          <h5 className="font-semibold h5-md">
            Dental implants can be used if you have
          </h5>
          <ul className="list-inside ">
            <li>Lost tooth due to trauma </li>
            <li> Are suffering from severe tooth decay</li>
            <li> Are going through a root canal failure</li>
            <li> Have gum disease </li>
            <li>If your teeth have suffered daily wear and tear </li>
          </ul>
        </div>

        <div id="benefits" className="mt-2 ml-4">
          <h5 className="font-semibold h5-md">Benefits of dental implants</h5>
          <span>Dental implants have a number of benefits. </span>
          <ul className="list-inside ">
            <li>Improves appearance</li>
            <li>They make eating easy</li>
            <li>Dental implants can improve your oral health</li>
            <li>They are durable and can last you a lifetime </li>
            <li>They improve your self-esteem</li>
            <li>They will help you speak clearly </li>
            <li>They are extremely comfortable to use </li>
          </ul>
        </div>
        <div id="plan" className="mt-2">
          <h5 className="font-semibold h5-md">Treatment plan</h5>
          <p>
            Please note that dental implants are not suitable for everyone,
            underlying oral health issues and health issues must be considered
            before proceeding. Dental issues such as tooth decay and gum disease
            can make your treatment less effective. Our dentist will thoroughly
            check your medical records, perform a dental check-up and confirm if
            you are suitable for the treatment. Once our dentist deems you are
            suitable for the treatment, your custom treatment will begin. To
            know more about our services, please get in touch with us. We serve
            patients in and around Colborne.
          </p>
        </div>
        <div className="mt-2">
          <h5 className="font-semibold h5-md">What can you expect?</h5>
          <span className="mt-2">
            Dental implant surgery involves multiple steps, including:
          </span>
          <ul className="mt-1 list-inside">
            <li>Removal of damaged tooth</li>
            <li>Jawbone preparation </li>
            <li>Implant placement</li>
            <li>Bone growth </li>
            <li>Providing time to heal</li>
            <li>Abutment placement</li>
            <li>Tooth placement</li>
          </ul>
        </div>
        <div className="mt-2">
          <h5 className="font-semibold h5-md">Maintenance</h5>
          <p className="para">
            A person who has undergone dental implant must brush and floss their
            teeth regularly. They must schedule a follow up with our dentist to
            monitor the implants and ensure that their gums are healthy. It is
            also essential to undergo regular dental cleanings.
          </p>
        </div>
        <div className="mt-2">
          <h5 className="font-semibold h5-md"> Trusted dental clinic</h5>
          <p className="para">
            We are a trusted and reliable dental clinic in Colborne. Our
            dentists are fully qualified and have many years of experience, we
            will provide you with top quality dental treatment at great prices.
            Over the years we have helped a number of our patients smile and
            speak confidentially. To book an appointment with us, please reach
            out to us now.{" "}
          </p>
        </div>
      </div>
    </>
  );
}
