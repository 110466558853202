import React, { useRef, useEffect, useState } from "react";
//import {FaArrowCircleUp} from 'react-icons/fa';
import { Button } from "./Style";


const ScrollButton = ({ props }) => {

    // const location = useLocation();
    // console.log("Path name is " ,location.pathname);

    const [ visible, setVisible ] = useState(false);
    // const [isPage, setisPage] = useState(false);


    // const scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth'
    //         /* you can also use 'auto' behaviour
    //             in place of 'smooth' */
    //     });
    // };

    const navigateTo = () => {

        //window.navigateTo("/appointment");
        if (typeof window !== "undefined") {
            window.location.href = "/appointment";
        }
    };

    // window.addEventListener("scroll", toggleVisible, false);
    // const navigate = useNavigate();

    useEffect(() => {

        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;

            if (scrolled > 300) {
                setVisible(true);
            } else if (scrolled <= 300) {
                setVisible(false);
            }
        };


        window.addEventListener("scroll", toggleVisible);
        // 👇️ remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);

    return (
        <div className="float-left">
            {/* <Link to="/appointment">
        <span> Reqeuest appointment</span>
        </Link> */}
            {/* <Button variant="link" onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none', float: 'right' }} > */}
            <div className="col-1">
                {visible &&
                    <Button
                        variant="link"
                        onClick={navigateTo}
                        style={{
                            backgroundColor: "#00cc99",
                            // display: visible ? "hidden" : "visible",
                            float: "right",
                        }}
                    >
                        {/* <div className="text-center meduse">
                    <span className="text-2xl font-bold text-black">
                        Request Appointment
                    </span>
                </div> */}
                        {/* <NavHashLink
                    to="/appointment"
                    activeStyle={{ color: "red" }}
                    isActive={(match, location) => {
                        return match?.url === location?.pathname
                    }}
                >
                    To Library
                </NavHashLink> */}
                        <div className="self-center mt-2 font-bold text-center floatingAppointment text-lgx">
                            <span className="ml-4 text-center d-flex font-Poppins">
                                {/* <i className="mr-4 text-white fa-solid fa-calendar-check"></i> */}
                                Request
                            </span>
                            <span className="ml-2"> Appointment</span>
                        </div>
                    </Button>
                }
            </div>
        </div>
        // <Button>
        //     <a href="#">
        //       <i className="text-2xl fa-solid fa-circle-up steelblue blue-color"></i></a>
        // <FaArrowCircleUp onClick={scrollToTop}
        // style={{display: visible ? 'inline' : 'none'}} />
        // </Button>
    );
};

export default ScrollButton;
