import React from "react";

export default function ImplantSupportedDentures() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Implant Supported Dentures</h1>

        <div className="mb-10">
          <p className="py-1 para">
            Conventional dentures temporarily sit on your gums, allowing them to
            be easily moved or loosened whenever you move your mouth. Implant
            Supported Dentures avoid this major setback, as they are permanently
            imbedded to implants inside your jaw. Implant supported dentures can
            be used for both the upper and lower jaw, but are more frequently
            made for the lower jaw because conventional dentures tend to fit
            better on the upper jaw.
          </p>
          <p className="py-1 para">
            The gum tissues around the implants will naturally fuse with the
            dentures and the healing process can typically take around 3-6
            months (healing time varies from patient to patient). Afterwards,
            additional surgeries will be required to expose the tops of the
            implants, in order to imbed the dentures. Implanted dentures need to
            be removed on a daily basis for routine cleaning and brushing, the
            same way that you would with traditional dentures.
          </p>
          <p className="py-1 para">
            Our team at Colborne Dental Office will find the right dentures that
            are the most suitable fit for your circumstance and that you find
            the most comfortable. Contact us today to work on an effective
            treatment plan for your implant supported dentures.
          </p>
        </div>
      </div>
    </>
  );
}
