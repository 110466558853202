import styled from 'styled-components';

export const Heading = styled.h1`
text-align: center;
color: green;
`;

export const Content = styled.div`
overflowY: scroll;
height: 2500px;
`;

export const Button = styled.div`
position: fixed;
 /* width: 30%; */
 right:0;
/* right: 0.54em; */
/* margin: auto; */
/* left:3:00rem; */
/* right: 2.01rem; */
bottom: 40px;
height: 60px;
 /* border-radius:15px; */
 border-top-left-radius:15px;
border-bottom-left-radius:15px;
/* font-size: 3rem; */
z-index: 1;
background-color: "#00cc99";
cursor: pointer;
/* @media only screen and (max-width: 600px) {
 
  [class*="col-"] {
    width: 100%;
  }
} */
/* color: gray; */
`
export const span = styled.span`
font-size: 5rem;
`