import { HelmetTag } from "components/HelmetTag";
import React from "react";
import { SERVICEDENTURE } from "assets/helmet";
export default function Dentures() {
  return (
    <>
      <HelmetTag
        title={SERVICEDENTURE.title}
        desc={SERVICEDENTURE.description}
      />
      <div className="container mb-5">
        <h1 className="text-center h1">Dentures in Colborne</h1>
        <p className="para">
          Have you lost your teeth due to decay or an accident? If yes, we
          suggest you get a denture. One must have all their teeth, ensuring
          your mouth functions optimally and overall oral health is in top
          condition. To schedule an initial consultation with our professional
          Colborne dentist, please do not hesitate to reach out to our team
          today.
        </p>
        <h5 className="py-2 font-semibold h5-md">
          When should one consider getting dentures in Colborne?
        </h5>
        <section>
          When a person experiences any kind of tooth loss, it is the right time
          for them to get dentures. Below mentioned are some common oral issues
          that could lead a person to choose dentures in Colborne:
          <ul className="py-2 list-decimal list-inside">
            <li>
              {" "}
              Substantial decay: Most tooth decays can be dealt with through a
              tooth filling or even getting a root canal. If the situation is
              worse, and you cannot recover a large part of your teeth, then
              getting a denture is the best solution.
            </li>
            <li>
              {" "}
              Gum diseases: Leaving your gums untreated for a long time can not
              only ultimately result in tooth loss, but also lead to a host of
              gum diseases, which could potentially affect the functionality of
              your teeth, including leading to other health problems as well.
              Getting a denture can help regain functionality.
            </li>
            <li>
              {" "}
              Mishaps: An accident can happen at any time to anyone. One of the
              most common injuries in an accident is tooth loss. In scenarios
              like this, a denture can restore your smile and boost your
              self-confidence.
            </li>
            <p className="py-2">
              For further information, please do not hesitate to contact our
              Colborne dentist today.
            </p>
          </ul>
          <div className="py-2">
            <h5 className="font-semibold h5-md"> Types of dentures:</h5>
            <p className="para">
              {" "}
              Based on the seriousness of your tooth’s condition and the number
              of teeth that need to be taken care of; our dentist in Colborne
              can suggest the right type of denture that would suit your dental
              needs perfectly. There are several types of dentures, but the two
              most common ones are:
            </p>
            <ul className="mt-2 list-inside">
              <li>
                {" "}
                <span className="font-semibold"> Partial dentures</span>
                <p className="para">
                  If you only have a few teeth missing, a great suggestion would
                  be to get only partial dentures. To fill the gap created by
                  missing teeth, they are moulded. The best part is, that these
                  dentures do not cover the entire mouth, and they can be
                  removed.
                </p>
              </li>
              <li>
                {" "}
                <span className="font-semibold"> Complete dentures</span>
                <p className="para">
                  In instances where the patient has lost all their natural
                  teeth, our denture clinic in Colborne recommends getting
                  complete dentures. Commonly referred to as false teeth, they
                  get can take some time to get used to, and they also are at
                  times made before the teeth or tooth is extracted.
                </p>
              </li>
            </ul>
          </div>
          <div className="py-2">
            <h5 className="font-semibold h5-md">
              {" "}
              What is the cost of dentures in Colborne?
            </h5>
            <p className="para">
              The cost is determined based on several factors. Whether you need
              dentures for the lower arch or upper arch. We will assess the
              damage of the tooth injury as well to determine what kind of
              denture is best suited for the patient. Additionally, most
              insurance plans cover the cost of dentures. We provide various
              payment options at our denture clinic in Colborne. For further
              details on dentures, please speak to our friendly team.
            </p>
            <h5 className="py-2 font-semibold h5-md">
              Schedule an appointment with Colborne Family Dental today!{" "}
            </h5>
            <p className="para">
              Our dentists in Colborne are committed to making your smile not
              only better, but also healthier. Our clinic is equipped with all
              the latest equipment, ensuring you get the best service possible
              in a safe and calm environment. We pride ourselves on offering
              patients the best possible service at all times. Getting dentures
              can be a hassle. With our dentists, you can rest assured the
              procedure will be undertaken in a stress-free and painless manner.
              Our payment options are flexible as well.
            </p>
            <p className="py-2 ">
              Contact us today at{" "}
              <span className="px-2 font-semibold">
                <i className="text-lg steelblue-color bi bi-telephone"></i>{" "}
                1-(905)-355-1419
              </span>
              to schedule an appointment.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}
