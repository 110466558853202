import BreadCrumbCDC from 'components/Breadcrumbs/NewBreadCrumb'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'

export default function Finance() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<>
			<Container id="apt" className='h-full basicTop'>
				<BreadCrumbCDC title="Financing Options"></BreadCrumbCDC>
				<section id="info-2" className="wide-60x info-section division">
					<div className="container">
						<div className="row d-flex ">


							{ /* TEXT BLOCK */}
							<div className="col-lg-6 mt-3">
								<div className="txt-block space-x-2">

									{ /* Section ID */}


									{ /* Title */}
									<h3 className="h3-md steelblue-color"> <i className="bi bi-cash-coin"></i>Payment Options</h3>

									{ /* Text */}
									<p>For your convenience, we accept the following cards.Payment is expected at the time services are performed. Contact our office to make other financial arrangements if necessary.
									</p>

									{ /* Options List */}
									<div className="row mt-3">

										<div className="col-xl-6">

											{ /* Option #1 */}
											<div className="box-list">
												<div className="box-list-icon lime-color"> <i className="bi bi-chevron-double-right"></i></div>
												<p className="p-sm">Visa Card</p>
											</div>

											{ /* Option #2 */}
											<div className="box-list">
												<div className="box-list-icon lime-color"> <i className="bi bi-chevron-double-right"></i></div>
												<p className="p-sm">MasterCard</p>
											</div>

											{ /* Option #3 */}
											<div className="box-list">
												<div className="box-list-icon lime-color"> <i className="bi bi-chevron-double-right"></i></div>
												<p className="p-sm">Debit</p>
											</div>
											<div className="box-list">
												<div className="box-list-icon lime-color"> <i className="bi bi-chevron-double-right"></i></div>
												<p className="p-sm">Cash</p>
											</div>
											<div className="box-list">
												<div className="box-list-icon lime-color"> <i className="bi bi-chevron-double-right"></i></div>
												<p className="p-sm">e-transfer</p>
											</div>
										</div>



									</div>	{ /* End Options List */}

								</div>
							</div>	{ /* END TEXT BLOCK */}

							{ /* TEXT BLOCK */}
							<div className="col-lg-6 mt-3">
								<div className="txt-block">

									{ /* Section ID */}


									{ /* Title */}
									<h3 className="h3-md steelblue-color">  <i className="bi bi-bank2"></i>Insurance</h3>

									{ /* Text */}
									<p className="txt-block">Our practice accepts most dental insurance programs currently offered to provide our patients with the best and most convenient service. To get you started, our accounting staff will oversee all of the necessary documents to claim your dental benefits; however, please bear in mind that your individual policy is an agreement between only you and your insurance company. You are responsible for your total obligation in the event that the benefits provided result in less coverage than anticipated. Our staff is happy to submit a pre-treatment estimate to your insurance company so that you can know what to expect.</p>

									<p className='mt-3 '>The fees applied to insured individuals’ services are the standard and accustomed fees charged to all of our patients for similar services. Your policy may distribute its allowances on a fixed schedule that may or may not coincide with our practice’s usual fees. Different insurance companies greatly vary in the insurance coverage offered, so we recommend shopping around a little before settling on one. In addition, time estimates can vary as well; some companies promptly respond to their claims while others delay payments for several months.</p>

								</div>
							</div>	{ /* END TEXT BLOCK */}
						</div>
					</div>
				</section>
			</Container>
		</>
	)
}
