import API from './api';



export async function createAppointment(appointment) {
  const options = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  }
  try {



    let res = await API.post('appointments', appointment, options)
    // if (res.status === 200) console.log(res)
    // if(res.status ===400) console.info('Success EWrror :' , res);

    return res.data
  }
  catch (err) {

    throw new Error(err);
  }

}

// export async function getAllAppointments() {
//   console.info('called');
//   await API.get('appointments')
//     .then((x) => {
//       // if(x)
//     })
// }
