import React from "react";

export default function NonSurgicalGumTherapy() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Non-Surgical Gum Therapy </h1>

        <div className="mb-10">
          <p className="py-1 para">
            One of the most common oral health complications that patients come
            in for is gum disease. Early signs of gum disease may be
            undetectable and evolves into gingivitis; signs may include bad
            breath, inflamed and sensitive gums. As the symptoms progress, they
            can become more painful and may even lead to severe damage such as
            gym recession and tooth loss. Regular dental visits can allow the
            dentist to check for any beginning detections of gingivitis or gum
            disease.
          </p>
          <p className="py-1 para">
            With the latest in dental technology, we use digital x-rays to
            monitor the state of your gums to ensure that we catch any early
            signs of gum disease and help you restore the state of your oral
            health. Consistent and regimented flossing can typically fight off
            further complications, if found during the gingivitis stage.
            However, once the disease has progressed into periodontal disease,
            our dental specialists will need to get rid of excess plaque
            build-up and treat any infections below the gumline. Through local
            medication, curettage, root-planing, and preventative medicine will
            be used to help fight the chronic infection and restrict the disease
            from spreading. Curettage helps to eliminate the source of infection
            by removing the soft tissues surrounding the tooth, allowing that
            part of your gum to heal.
          </p>
          <p className="py-1 para">
            Root-planing is a technique used to smooth out the infected root’s
            surface, furthering the healing process for your gums.Each patient
            recovers at a different pace, which is why we recommend that
            multiple follow up visits are required to allow our dental team to
            monitor the healing progress. If you are starting to show any early
            signs of gingivitis or gum disease, contact Colborne Dental Office
            to schedule an appointment and treat it as soon as possible.
          </p>
        </div>
      </div>
    </>
  );
}
