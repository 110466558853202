import React from "react";

export default function FullMouthRestoration() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Full Mouth Restoration </h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/FullMouthRestoration/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
          <img
            src="imagesx/colborne/services/FullMouthRestoration/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div className="mb-10">
          <div>
            <p className="py-1 para">
              Certain medical cases will require full reconstruction, rebuilding
              both the upper and lower set of teeth to restore a healthy smile.
              Dr. Appan and the Colborne Dental staff all have extensive years
              of dental experience to help make a treatment plan customized to
              your unique situation. Be confident in your smile and allow our
              friendly team to work on a full mouth restoration plan for you.
              Those needing a full mouth restoration will have symptoms such as:
            </p>
            <ul className="px-20 py-1 font-semibold list-disc list-inside">
              <li> Missing teeth due to decay or an external injury</li>{" "}
              <li> Fractured and cracked teeth</li>{" "}
              <li> Heavily damaged, highly eroded teeth</li>{" "}
              <li>
                {" "}
                Chronic biting issues (ie. pain, difficulty speaking or chewing)
              </li>
            </ul>
          </div>
          <p className="py-1 para">
            Patients may suffer from one or more of these symptoms and may even
            have complications the require a procedure to rebuild the whole set
            of teeth, bite, and gums. Dr. Appan will recommend the best way to
            restore your teeth – whether it will be a combination or group of
            options including: dental crowns/bridges, porcelain veneers,
            (non-surgical) gum therapy, root canal therapy, and dental implants.
            A treatment plan will outline the exact procedure and long term
            steps towards a full recovery and new smile!"
          </p>
          <p className="py-1 para"></p>
          <p className="py-1 para"></p>
        </div>
      </div>
    </>
  );
}
