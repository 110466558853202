import API from './api';



export async function SendContactUsEnquiry(contactus) {
    const options = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    }
    try {
        let res = await API.post('contactus', contactus, options)
        return res.data
    }
    catch (err) {
        console.error('Error From CDC ', err);
        throw new Error(err);
    }

}
