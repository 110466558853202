import { useEffect, useState } from "react";
import { oservices } from "assets/data/ourservices";
import * as Services from "components/OurServices/ServiceItems";
import { useLocation } from "react-router-dom";

const ServicesMob = () => {

    const [ selected, setSelected ] = useState("AmalgamFree");

    const [ selectedService, setSelectedService ] = useState(null);

    const location = useLocation();

    const renderServiceItem = (name) => {
        if (name) {
            const ServiceSelected = Services[ name ];
            return <ServiceSelected />;
        }
    };
    const handleChange = (event) => {
        setSelected(event.target.value);
        renderServiceItem(event.target.value);
    };

    useEffect(() => {
        if (location.state !== undefined && location.state !== null) {
            const { toShowItem } = location.state;

            setSelectedService(toShowItem);
        } else {
            setSelectedService("AmalgamFree");
        }
    }, []);

    return (
        <>
            <header className="mt-4">
                <h5 className="h5-md header"> Please Select a Service</h5>
            </header>
            <div className="mt-4">
                <select
                    className="form-select"
                    value={selected}
                    onChange={handleChange}
                >
                    {oservices.map((option) => (
                        <option key={option.name} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <article className="mt-2">
                    <div className="inline-flex flex-row flex-wrap gap-2">
                        {/* {
                            serviceImgarr.map((x, key) => {
                                var imgName = selected.replace(/\s/g, "");
                                var path =
                                    "imagesx/colborne/services/" +
                                    imgName +
                                    "/" +
                                    x +
                                    ".jpeg";

                                return (
                                    <img key={key} src={path} className='rounded-lg shadow-lg ' alt="Service"></img>

                                )
                            })
                        } */}
                    </div>
                    {/* <p className='mt-2'>
                        {service}
                    </p> */}
                    {renderServiceItem(selected)}
                </article>
            </div>
        </>
    );
};

export default ServicesMob;
