import GetDirectionsBoxMob from "components/Header/GetDirectionsBoxMob";
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import './styles.css';

export default function HeroSliderNewMobile() {
  return (
    <>
      <div>
        <section
          id="hero-12mobile"

          className="bg-gray w-fulls servicess-sectionx divisionx "
        >
          <div className="col-md-8 col-lg-7 col-xl-6">
            <div className="mb-5 align-middle hero-txt ">
              {/* Title */}

              <p className="pt-5 text-lg font-semibold text-center h3-md steelblue-color">
                Welcome To Colborne Family Dental
              </p>
              <p className="mt-3 font-semibold text-center text-gray-700 display-6 font-lato ">
                WE CARE FOR YOUR SMILE
              </p>

              <p className="mt-2 text-2xl text-center text-gray-600 p-lgx">
                Family Dentist in Colborne,Ontario
              </p>
            </div>
          </div>
          <div className="container ml-12 space-x-3 ">
            <div className="p-0 mt-3 row">

              <div id="hero-block" className="w-4/12 ml-3 col sbox-2x icon-xxsx">
                <Link to="/appointment">
                  <i className="text-3xl bi bi-calendar2-check blue-color"></i>

                  <div className="sbox-2-txt">
                    <span className="h5-sm -ml-7 font-semiboldx steelblue-color">
                      New Appointment
                    </span>
                  </div>
                </Link>
              </div>


              <div className="w-4/12 col sbox-2x icon-xxs2 ">
                <Link to="/newpatient">
                  <i className="text-3xl bi bi-person blue-color"></i>
                  <div className="sbox-2-txt -ml-7 ">
                    <span className="h5-sm steelblue-color font-semiboldx "> New Patient</span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="p-0 mt-4 row">
              <div className="w-4/12 -ml-2 sbox-2x col icon-xxs">
                <a href="tel:905-355-1419">
                  <i className="text-3xl bi bi-telephone blue-color"></i>

                  <div className="sbox-2-txt -ml-7 ">
                    <span className="h5-sm font-semiboldx steelblue-color">Call Office</span>
                  </div>
                </a>
              </div>
              <div className="w-4/12 -ml-2 sbox-2x col icon-xxsx">
                <Link to="/aboutus">
                  <i className="text-3xl bi bi-people blue-color"></i>

                  <div className="-ml-4 sbox-2-txt ">
                    <span className="h5-sm steelblue-color font-semiboldx">About Us</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="p-0 mt-4 row">
              <div className="w-4/12 -ml-2 sbox-2s col icon-xxss">
                <HashLink smooth to={"/#contactus"}>
                  {/* <i className="text-3xl bi bi-geo-alt blue-color"></i> */}
                  <i className="text-3xl bi bi-card-text blue-color"></i>
                  <div className="sbox-2-txt -ml-7 ">
                    <h3 className="h5-sm steelblue-color font-semiboldx ">Contact Us</h3>
                  </div>
                </HashLink>
              </div>

              <div className="w-4/12 -ml-2 sbox-2x col icon-xxsx">
                <Link to="/finance">
                  <i className="text-3xl bi bi-cash-coin blue-color"></i>
                  <div className="sbox-2-txt -ml-7 ">
                    <h3 className="h5-sm steelblue-color font-semiboldx">Financing Options</h3>
                  </div>
                </Link>
              </div>

            </div>
            <div className="p-0 mt-3 row">
              <div className="w-4/12 -ml-2 sbox-2x col icon-xxsx">
                <Link to="/blog">
                  <i className="text-3xl bi bi-bootstrap blue-color"></i>
                  <div className="-ml-1 sbox-2-txt">
                    <h3 className="h5-sm steelblue-color font-semiboldx"> Blog</h3>
                  </div>
                </Link>
              </div>
              <div className="w-4/12 -ml-2 sbox-2x col icon-xxsx">
                <Link to="/services">

                  <i className="text-3xl bi bi-gear blue-color"></i>
                  <div className="-ml-4 sbox-2-txt">
                    <h3 className="h5-sm steelblue-color font-semiboldx">Services</h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>

        <div className="p-1 mt-4 ml-3 border-0 ">
          <div className="">
            <div className="space-x-1 d-flex ">
              <i className="text-xs bi bi-compass"></i>
              <small className="ml-3 font-semibold">Get Directions: </small>
            </div>
            <GetDirectionsBoxMob
              name="mobDirections"
              btncss={" text-center mt-2 w-32 bg-blue blue-hover"}
              inputcss={"w-40"}

              iconcss={"text-white space-x-2"}
            />
          </div>
        </div>
      </div >
    </>
  );
}
