import { NEWPATIENTS } from "assets/helmet";
import BreadCrumbCDC from "components/Breadcrumbs/NewBreadCrumb";
import { HelmetTag } from "components/HelmetTag";
import LocateUs from "components/Locateus/LocateUs";
import ScrollButton from "components/ScrollTop/ScrollButton";
import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Newpatient() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useDocumentTitle(NEWPATIENTS.title, NEWPATIENTS.desc);

  return (
    <>
      <HelmetTag title={NEWPATIENTS.title} desc={NEWPATIENTS.description} />
      <Container id="apt" className="h-full">
        <BreadCrumbCDC title="New Patient"></BreadCrumbCDC>
        <section id="services-7" className="w-full servicess-section division">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 section-title">
              <h4 className="h4-md steelblue-color ">New Patients</h4>

              <p className="p-0 justify-items-stretch">
                At Colborne Family Dental Studio, Your first visit is all about
                your comfort, happiness, and your health. Our practice is
                committed to providing you and your family with safe and
                high-quality dental care.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* SERVICE BOXES */}
              <div className="mb-2 col-lg-8">
                <div className="row">
                  {/* SERVICE BOX #1 */}
                  {/* <div className="col-md-6">
                    <div className="sbox-7 icon-xs wow fadeInUp">
                      <div className="sbox-7-txt">
                        <div className="inline-flex mt-4 mb-2">
                          <span className="flaticon-030-clinic-history-7 blue-color"></span>
                          <h5 className="h5-sm steelblue-color font-Poppins">
                            Patient Forms
                          </h5>
                        </div>

                        <div className="box-list m-top-15">
                          <div className="box-list-icon blue-color">
                            <i className="bi bi-chevron-double-right"></i>
                          </div>
                          <div className="space-x-3 d-flex">
                            <a
                              href="../../assets/doc/New-Patient-Form-2.pdf"
                              className="text-blue-600 d-flex"
                            >
                              <p className="inline-flex ">
                                {" "}
                                Offline Registration form{" "}
                              </p>
                              <img
                                src={pdf}
                                alt="pdf"
                                className="ml-2 img-10"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="inline-flex box-list m-top-15">
                          <div className="box-list-icon blue-color">
                            <i className="bi bi-chevron-double-right"></i>
                          </div>
                          <div className="inline-flex">
                            <Link
                              to="/precovidscreen"
                              className="text-blue-600"
                            >
                              Covid Pre-screening form
                              <i className="ml-1 text-black bi bi-box-arrow-up-right"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="box-list m-top-15">
                          <div className="box-list-icon blue-color">
                            <i className="bi bi-chevron-double-right"></i>
                          </div>
                          <div className="space-x-3 d-flex">
                            <Link to="/fileupload" className="text-blue-600">
                              {" "}
                              Upload Forms{" "}
                              <i className="ml-2 text-black bi bi-box-arrow-up-right"></i>
                            </Link>
                          </div>
                        </div>



                        <div className="box-list m-top-15">
                          <div className="box-list-icon blue-color">

                          </div>
                          <div className="space-x-3 d-flex">
                            <span className="text-transparent">
                              {" "}
                              Offline Registration form
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "} */}
                  <div className="col-md-12">
                    <HashLink smooth to={"/newpatient#locateusx"}>
                      <div className="sbox-7 icon-xs ">
                        {/* Icon */}

                        {/* Text */}
                        <div className="sbox-7-txt">
                          {/* Title */}
                          <div className="inline-flex mt-4 mb-2">
                            <span className="flaticon-064-hospital-6 blue-color"></span>
                            <h5 className="h5-sm steelblue-color font-Poppins">
                              Office Location
                            </h5>
                          </div>

                          {/* <h5 className="h5-sm steelblue-color">
                            Office Location
                          </h5> */}

                          {/* Text */}

                          <div className="ml-10">
                            <Row className="h-50">
                              10 Victoria Square, Colborne,
                            </Row>
                            <Row>ON K0K 1S0 </Row>
                          </div>

                          <div className="inline-flex mt-3">
                            <span className="px-2 text-blue">
                              <i className="mr-2 bi bi-compass blue-color"></i>
                              Locate us on Map
                            </span>
                          </div>
                        </div>
                      </div>
                    </HashLink>
                  </div>{" "}
                  <div className="col-md-6">
                    <div className="sbox-7 icon-xs " data-wow-delay="0.6s">
                      <span className="flaticon-096-calendar blue-color"></span>

                      <div className="sbox-7-txt">
                        <h5 className="h5-sm steelblue-color">
                          On Appointment Day
                        </h5>

                        <p className="p-sm a.share-ico list-item">
                          <Link to="/"> Medical History Review</Link>
                        </p>
                        <p className="p-sm a.share-ico list-item">
                          <Link to="/"> Thorough Examination</Link>
                        </p>
                        <p className="p-sm a.share-ico list-item">
                          <Link to="/">Cleaning</Link>
                        </p>
                        <p className="p-sm a.share-ico list-item">
                          <Link to="/"> Treatment Plan</Link>
                        </p>
                        <p className="p-sm a.share-ico list-item">
                          <Link to="/"> Dental Hygienist Review</Link>
                        </p>
                        <p className="text-transparent p-sm a.share-ico list-item">
                          Dental Hygienist Review
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sbox-7 icon-xs ">
                      <div className="sbox-7-txt">
                        <div className="inline-flex mt-4 mb-2">
                          <span className="flaticon-132-medicine-book blue-color"></span>
                          <h5 className="h5-sm steelblue-color font-Poppins">
                            Insurance
                          </h5>
                        </div>

                        {/* <span className="flaticon-132-medicine-book blue-color"></span>
                        <h5 className="h5-sm steelblue-color">Insurance</h5> */}

                        <div className="box-list m-top-15">
                          <div className="box-list-icon blue-color">
                            <i className="bi bi-chevron-double-right"></i>
                          </div>
                          <div className="space-x-1 ">
                            <div>
                              <Link to="/">No Insurance?</Link>
                              <div className="mt-2 col-md-12 col-sm-12 col-lg-12">
                                <a href="tel:905-355-1419">
                                  <button
                                    type="button"
                                    className="rounded btn-sm btn bg-blue blue-hover"
                                  >
                                    <div className="inline-flex space-x-2 text-base">
                                      <span className="gap-2 font-semibold text-white">
                                        <i className="text-white bi bi-telephone" />
                                        905-355-1419
                                      </span>
                                    </div>
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="box-list m-top-15">
                          <div className="mt-5 box-list-icon blue-color"></div>
                          <div className="space-x-3 d-flex">
                            <span className="text-transparent">
                              No insurance
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>{" "}
              <div className="col-lg-4">
                <div
                  className="services-7-table blue-table mb-30 "
                  data-wow-delay="0.6s"
                >
                  <div className="space-x-3 d-flex">
                    <span className="text-white flaticon-001-clock-1 "></span>
                    <h4 className="h4-xs">Opening Hours:</h4>
                  </div>

                  <h3 className="text-2xl p-sm text-yellow-50">
                    Colborne Dental Clinic
                  </h3>

                  {/* Table */}
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Mon – Fri</td>
                        <td> - </td>
                        <td className="text-right">8:30 AM - 5:00 PM</td>
                      </tr>
                      <tr>
                        <td>Sat</td>
                        <td> - </td>
                        <td className="text-right">8:30 AM - 3:00 PM</td>
                      </tr>
                      <tr className="last-tr">
                        <td>Sun</td>
                        <td>-</td>
                        <td className="text-right">CLOSED</td>
                      </tr>
                    </tbody>
                  </table>

                  <div>
                    <h5 className="h5-sm">No Insurance Or Paperwork?</h5>

                    {/* Text */}
                    <p className="p-sm">
                      Please call us at <i className="mr-2 bi bi-telephone" />
                      905-355-1419
                    </p>
                  </div>
                </div>
              </div>{" "}
            </div>{" "}
          </div>{" "}
          {/* End container */}
        </section>
        <div id="locateusx">
          <LocateUs />
        </div>
      </Container>
      <ScrollButton />
    </>
  );
}
