import React from "react";

export default function InvisalignInvisibleBraces() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Invisalign Invisible Braces </h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/InvisalignInvisibleBraces/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md "
          />
          <img
            src="imagesx/colborne/services/InvisalignInvisibleBraces/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div>
          <p className="py-1 para">
            Feeling self conscious about your smile? Invisalign Invisible braces
            are the best solution to help straighten your teeth or fix
            overcrowding. While traditional metal braces are directly bonded to
            your teeth, Invisalign invisible braces uses aligners that can be
            removed when eating, brushing, and flossing.
          </p>
          <p className="py-1 para">
            Unlike obvious looking metal braces, the Invisalign aligners are
            made of high quality, durable clear plastic making them barely
            noticeable. Feel more confident about showing off your teeth without
            the worry of others seeing your aligners.
          </p>
          <div>
            <ul className="list-disc list-inside">
              <li>
                <span className="font-semibold">How does Invisalign work?</span>
                <p className="py-1 para">
                  The process of having Invisalign Invisible braces will require
                  the patient to wear a set of aligners for at least 21 hours
                  per day, for 2 week periods at a time. Aligners must be worn
                  at all times, but they can be removed when brushing, flossing,
                  or eating. Invisalign braces need to have contact with the
                  teeth for a required amount of time in order to show results.
                  The number of aligners that will need to be worn is dependent
                  on the severity of the misalignment. This treatment process
                  will likely last for one year, with periodical visits to our
                  dental office to monitor the progress.
                </p>
              </li>
              <li>
                <span className="font-semibold">
                  How are the Invisalign Aligners made?
                </span>
                <p className="py-1 para">
                  The Invisalign Aligners are custom made to fit personally to
                  your teeth. Our dental team will create impressions of your
                  teeth during the checkup. After that, the impressions will be
                  sent to a dental lab to make the aligners. Once ready for
                  pickup, our knowledgeable staff will instruct you on the daily
                  use and care to ensure that you’ll receive effective treatment
                  with friendly service.
                </p>
              </li>
            </ul>
          </div>
          <p>
            Contact Colborne Dental Office to setup an appointment with Dr.
            Appan in Colborne, Ontario.
          </p>
        </div>
      </div>
    </>
  );
}
