import React from "react";

export default function BondedFillings() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1">Bonded Fillings</h1>
        <div className="flex flex-wrap justify-center w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/BondedFillings/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md "
          />
          <img
            src="imagesx/colborne/services/BondedFillings/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div className="py-2 mb-10">
          <p className="py-1 para">
            {" "}
            Metal fillings used to be the only way to treat cavities and decayed
            teeth, which is an effective solution, however, it can be
            uncomfortable to have metal bonded to your teeth. Additionally, they
            distinctly stand out from the colour of your teeth.
          </p>{" "}
          <p className="py-1 para">
            Bonded fillings are a natural-looking alternative made of composite
            resin materials. Tooth-coloured bonded fillings offer a strong,
            durable form of dental restoration that protects your teeth. The
            resin can be customized to match the exact colour and shade of
            anyone’s smile. A special UV light is used to create a durable bond
            and the composite resin has been molded to adapt to the tooth and
            appear natural.
          </p>{" "}
          <p className="py-1 para">
            When a patient is getting a bonded filling, the damaged tooth (or
            teeth) are first cleaned and dried. Afterwards, the decayed part of
            the tooth is removed, and replaced by resin. Once the bond is
            applied, the tooth/teeth are buffed to shine and give a polished
            finish.
          </p>{" "}
          <p className="para">
            {" "}
            As a popular choice for dental restoration, bonded fillings can be
            conveniently completed within one appointment.
          </p>
        </div>
      </div>
    </>
  );
}
