import { Hero } from 'components/Jumbotron/Hero';
import HeroSliderNewMobile from 'Mobile/components/HeroSliderNewMobile';
import React from 'react';
import { BrowserView, MobileView } from "react-device-detect";

export default function HeroLandingPage() {
    return (
        <>
            <BrowserView>
                <Hero />
                {/* <HeroSliderNew /> */}
            </BrowserView>

            <MobileView>
                <HeroSliderNewMobile />
            </MobileView>
        </>
    )
}
