import React from "react";

export default function RootCanalTherapy() {
  return (
    <>
      <div className="container mb-5">
        <h1 className="text-center h1"> Root Canal Therapy </h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/RootCanalTherapy/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-2xl"
          />
          <img
            src="imagesx/colborne/services/RootCanalTherapy/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-2xl"
          />
        </div>
        <div className="mb-10">
          <p className="py-1 para">
            When the inner tissues of a tooth are decayed, root canal therapy is
            required to allow the dentist to extract the afflicted root/dental
            pulp without removing the tooth entirely. The dental pulp refers to
            the many blood vessels, nerves, and inner tissues that extend from
            the crown right down to the jawbone, that help keep the tooth
            healthy. Caused by either infection or external injury, bacteria
            enters inside the tooth and then deteriorates the tooth resulting in
            pain, swelling, and damage surrounding that tooth.
          </p>

          <p className="py-1 para">
            <span className="font-semibold"> Root canal therapy</span> is the
            ideal treatment to avoid the complication from worsening or
            spreading to other teeth.\n By removing the dead tissues and dental
            pulp, it will alleviate the pain and discomfort caused by the
            infected tooth. Depending on the severity of damaged tooth and
            dental pulp, a root canal treatment may take more than one
            appointment to cure. The inner pulp chamber must be thoroughly
            cleaned, the cavity must be resealed, and then a dental crown is
            placed on top to protect the tooth from further infection.
          </p>
          <p className="py-1 para">
            Proper care and a good dental hygiene regimen is required to improve
            your oral health after a root canal therapy procedure."
          </p>
        </div>
      </div>
    </>
  );
}
