export const teamInfo = {
    "dentist": [
        {
            "name": "Dr.Tovi_Luong",
            "filename": "Dr.Tovi_Luong",
            "title": "Dentist",
            "overview": "Dr. Tovi Luong graduated with a Doctor of Dental Surgery (DDS) from the University of Toronto, Toronto, Ontario, in 1989. Dr. Luong is well experienced in all aspects of general dentistry, including dentures, extractions and root canal treatment. Dr. Luong is a good standing member of the Ontario Dental Association and RCDSO. Dr. Luong is the previous owner and founder of Colborne dental. He is in his semi-retirement stage, wh ere he enjoys travelling."
        },
        {
            "name": "Dr.Le",
            "filename": "Dr.Le",
            "title": "Dentist",
            "overview": "Dr. Vincent Duc Vinh Le earned a Bachelor of Science Degree at the University of Toronto in 1998. He graduated with his Doctor of Dental Surgery degree from the U of T Faculty of Dentistry in 2002. Since then, he has been practicing most aspects of general dentistry in Colborne, Ontario. Dr Le continues his commitment to dental excellence as an active member of Ontario Dental Association. He regularly attends seminars and courses to keep up to date with the latest developments in dentistry. He combines skills and experience in his work with a kind, caring attitude, and gentle touch. In his spare time, Dr Le enjoys biking and spending time with his family and friends"
        },
        {
            "name": "Dr.Appan",
            "title": "Dentist",
            "filename": "Dr.Appan",
            "overview": "Dr. Suresh Appan completed Doctor of Dental Surgery (D.D.S) at Western University in London, Ontario in 2015. He is proficient in all aspects of General Dentistry including Cosmetic Dentistry, Extraction and Root Canal Therapy. He has received additional training and certification in Nitrous Oxide sedation and Invisalign. He regularly participates in continuing education programs to keep informed and to give the best quality care to his patients. He is very much recognized for his warmth, friendliness, and approachable manner. His desire is to ensure that all his patients have a positive experience while in his care and are extremely satisfied with their results. His goal as a dentist is to ensure the patient is comfortable before starting treatment and to leave with a BIG SMILE."
        },
        {
            "name": "Jenn Brunet",
            "title": "Office Manager",
            "filename": "Jenn-Brunet",
            "overview": "I am a graduate of Durham College’s Dental Assisting Program (class of 2006) and a certified member of the Ontario Dental Assistants Association. When you work with such a great team it makes going to work a real pleasure. I also enjoy horseback riding, camping, relaxing at home with my husband and our two children, and spending time with our family and friends."
        },
        {
            "name": "Heather",
            "title": "Dental Assistant",
            "filename": "Heather",
            "overview": "In 2009 I graduated from Northern Alberta Institute of Technology in the Dental Assistant program. After spending several years in the dental field, I joined Colborne Family Dental in 2018. Outside of work, I enjoy spending time with my family, camping, and playing baseball."
        },
        {
            "name": "Shari Kelly",
            "title": "Dental Hygienist",
            "filename": "Shari_Kelly",
            "overview": "I have been in the dental field since 1990 as a Dental Assistant and Receptionist. I graduated from Maxwell College in 2010 as a Dental Hygienist. I have three daughters named Kelly, Alexis, and Logan. I also have a grandson named Nohlan. I have two cats and a dog. I have a very busy house and I wouldn't have it any other way! I love to sing and I am working on learning to play the guitar. I like to travel and enjoy camping and hiking."
        },
        {
            "name": "Nikki Peplinski",
            "title": "Dental Assistant",
            "filename": "Nikki_Peplinski",
        },
        {
            "name": "Heidi Burzdzius",
            "title": "Dental Assistant",
            "filename": "Heidi_Burzdzius"
        },

        {
            "name": "Avery Mccrory",
            "title": "Dental Hygienist",
            "filename": "Avery_Mccrory"
        },
        {

            "name": "Shaiyan Lowes",
            "title": "Dental Assistant",
            "filename": "Shaiyan_Lowes"
        },
        {
            "name": "Rachel Alexander",
            "title": "Dental Hygienist",
            "filename": "Rachel_Alexander"
        }

    ]
};