import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import App from "./App";
import "./index.css";
import TagManager from 'react-gtm-module';


const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_ID
}

//OPEN the below for PRODUCTION
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App>
        <Helmet prioritizeSeoTags>
          <title>Colborne Family Dental Care</title>
          <meta
            name="description"
            content="Welcome to Colborne Family Dental Clinic, services we offer Whitening, implants, Crowns, Invisalign, extractions. Please call us at 905-355-1419 for more information"
          />
        </Helmet>
        {/* {(!process.env.NODE_ENV || process.env.NODE_ENV !== "development") && (
          <Helmet prioritizeSeoTags>
            <meta
              name="google-site-verification"
              content="SY5jQ6wY8M2ed8_lTL2Bkyq6YStX3mCjpZ0UXlhhybo"
            />
          </Helmet>
        )} */}
      </App>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
