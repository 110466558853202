import React, { useState } from "react";
import "./style.css";

export default function FloatingInput({
    type = "text",
    label,
    name,
    classm,
    star,
    tabIndex,
    ...props
}) {
    const [value, setValue] = useState("");
    const lblId = name + Math.floor(Math.random() * 876400) + 1;
    function handleChange(e) {
        setValue(e.target.value);

    }
    return (
        <div className="input-container">
            {star ? "*" : ""}
            <input
                tabIndex={tabIndex}
                className={classm}
                name={name}
                type={type}
                value={value}
                onChange={handleChange}
                aria-labelledby={lblId}
            />
            <label id={lblId} className={value && "filled"} htmlFor={name}>
                {label}
            </label>
        </div>
    );
}
