// useDocumentTitle.js
import { useRef, useEffect } from 'react'

function useDocumentTitle(title, description, prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
        document.description = description;
    }, [title, description]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }
    }, [])
}

export default useDocumentTitle;