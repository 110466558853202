import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

export default function BreadCrumbCDC({ title, ...props }) {
  const titleStr = title.toLowerCase();

  return (
    <div style={{ display: "block", padding: 10 }}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        {props.name ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" + titleStr }}>
              {title}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{props.name}</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        )}
      </Breadcrumb>
    </div>
  );
}
