import BreadCrumbCDC from "components/Breadcrumbs/NewBreadCrumb";
import format from "date-fns/format";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Button, Fade } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createAppointment } from "services/Appointmentsapi";
import ButtonGroup from "UI/ButtonGroup/ButtonGroup";
import { InputField } from "UI/InputField";
import { TextAreaField } from "UI/TextAreaField";
import { disableAppointTimes, getAllSundays, validate } from "./constants";
import Sidebar from "./sidebar";
import "./styles.css";

// TODO change formik form to React Hook form coz of Performance
//See: https://apiumhub.com/tech-blog-barcelona/react-form-libraries-comparison-formik-vs-react-hook-form/

export default function NewAppointmentC() {
  // const emailInput = useAutoFocus();
  const [showIsSubmit, setshowIsSubmit] = useState(false);

  //#region State
  var d = new Date();
  //var defaultDate = format(new Date(), "MMM-dd-yyyy");
  const [messageDisabled, setmessageDisabled] = useState(true);
  const [response, setResponse] = useState({});
  const [alertvariant, Setalertvariant] = useState("success");
  const maxDate = d.setMonth(d.getMonth() + 3);
  const sundays = getAllSundays(new Date(), maxDate);
  const [showInfo, setshowInfo] = useState(false);
  const [startDate, setStartDate] = useState();
  const [pstartDate, setpStartDate] = useState();
  // const nameInput = useRef(null);
  const [show, setShow] = useState(false);
  //#endregion
  //#region Values
  const timerRange = [
    "08:30",
    "09:30",
    "10:30",
    "11:30",
    "12:30",
    "13:30",
    "14:30",
    "15:30",
  ];

  const msg = {
    type: "success",
    message:
      "Your appointment request has been Successfully placed, Our representative will call you shortly",
    note: "",
  };
  //#endregion

  //const ref = useRef(null);

  const handleSubmit = (values, actions) => {
    setshowIsSubmit(true);

    // const request = {
    //   name: values.name,
    //   appointmentdate: values.appointmentdate
    //     ? defaultDate
    //     : values.appointmentdate,
    //   phone: values.phone ? "" : values.phone,
    //   email: values.email ? "" : values.email,
    //   visitdetail: values.visitdetail ? "" : values.visitdetail,
    //   appointmenttime: values.app ? "" : values.appointmenttime,
    //   reason: values.reason ? "" : values.reason,
    //   message:values
    // };
    createAppointment(values)
      .then((response) => {
        // console.log(response);

        setResponse({
          note: "Request Id : " + response,
          message: msg.message,
        });

        Setalertvariant("success");
        setShow(true);
      })
      .catch((err) => {
        setResponse({
          note: "",
          message: "Request failed , Please try again",
        });
        Setalertvariant("warning");
        setShow(true);

        setTimeout(() => {
          setShow(false);
        }, 6000);
        //  actions.resetForm();
      })
      .finally(() => {
        setshowIsSubmit(false);
        actions.setSubmitting(false);
        actions.resetForm();
        scrollup();
        // setShowX(true);
      });
    //getAllAppointments();
  };

  // const useFocus = () => {
  //   const htmlElRef = useRef(null);
  //   const setFocus = () => {
  //     htmlElRef.current && htmlElRef.current.focus();
  //   };

  //   return [htmlElRef, setFocus];
  // };

  //const [inputRef, setInputFocus] = useFocus();

  const switchReason = (reason) => {
    if (reason === "other") {
      setmessageDisabled(false);
      setshowInfo(true);
      //   setInputFocus(true);
    } else {
      setmessageDisabled(true);
      setshowInfo(false);
      //   setInputFocus(false);
    }
  };

  // const [startTime, setStartTime] = useState(
  //   setHours(setMinutes(new Date(), 30), 8)
  // );

  const scrollup = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    scrollup();
  }, []);

  //const auditTimeStamp = "25-apr-2022"; // format(new Date(), "MMM-dd-yyyy HH:mm:ss aa");
  var today = new Date(),
    date =
      today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear(); //  + "  " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

  // const location = useLocation();
  // console.log("Path name is " ,location.pathname);
  //const [showX, setShowX] = useState(true);
  return (
    <>
      {/* APPOINTMENT PAGE
			============================================= */}
      {show && (
        <Alert
          id="aptAlert"
          variant={alertvariant}
          closeLabel="Close"
          onClose={() => {
            setShow(false);
            setResponse({});
          }}
          dismissiblez
        >
          <h3>{response.message}</h3>
          <hr className="mt-2" />
          <span> {response.note}</span>
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-yellow">
              <i className="bi bi-x-circle"></i> Close
            </Button>
          </div>
        </Alert>
      )}
      <div id="apt" className="container basicTopx">
        <BreadCrumbCDC title="Request Booking" />
        <section
          id="services-7"
          className="w-full bg-lightgreyx servicess-section division "
        >
          {/* <section
          id="appointment-page"
          className=" appointment-page-section division"
        > */}
          <div className="py-2 text-center">
            <span className="font-light h1 steelblue-color">
              Book an Appointment
            </span>
            <p className="space-x-2 text-right sm:visible md:hidden lg:hidden xl:hidden">
              <i className="bi bi-info-circle text-blue-600 "></i>
              <a href="#sidebar" className="text-sm text-blue-600">
                Instructions
              </a>
            </p>
          </div>
          <div className="row">
            <div className="max-w-full"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="py-2 txt-block ">
                    <div className="col-md-7 col-lg-12">
                      <div className="mb-2 form-holder">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            email: "",
                            name: "",
                            phone: "",
                            requestInit: date,
                          }}
                          validationSchema={validate}
                          onSubmit={handleSubmit}
                        >
                          {(props) => {
                            return (
                              <Form
                                onSubmit={
                                  props.isSubmitting
                                    ? (e) => {
                                        e.preventDefault();
                                      }
                                    : props.handleSubmit
                                }
                                name="contactForm"
                                className="row contact-form"
                              >
                                {/* Name */}
                                <div className="col-md-12 col-lg-6">
                                  <div className="form-group">
                                    <InputField
                                      autoFocus
                                      tabIndex={0}
                                      label="Name"
                                      required={true}
                                      type="text"
                                      name="name"
                                      autoComplete="off"
                                      star
                                      onBlur={props.handleBlur}
                                    />
                                    <div className="help-block with-errors"></div>
                                  </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-12 col-lg-6">
                                  <div className="form-group">
                                    <InputField
                                      autoComplete="off"
                                      type="email"
                                      name="email"
                                      label="E-mail"
                                      star
                                      onBlur={props.handleBlur}
                                    />
                                    <div className="help-block with-errors"></div>
                                  </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md-12 col-lg-6">
                                  <div className="form-group">
                                    <InputField
                                      label="Contact Number"
                                      type="tel"
                                      name="phone"
                                      star
                                      onBlur={props.handleBlur}
                                    />

                                    <div className="help-block with-errors"></div>
                                  </div>
                                </div>

                                <div className="col-md-12 col-lg-6">
                                  <div className="form-group ">
                                    <label className="font-semibold">
                                      Visit Details:
                                    </label>

                                    <div
                                      role="group"
                                      aria-labelledby="my-radio-group"
                                      className="mt-2 space-x-3 d-flex"
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name="visitdetail"
                                          value="New Patient"
                                        />
                                        <span className="ml-1">
                                          New Patient
                                        </span>
                                      </label>
                                      <label>
                                        <Field
                                          type="radio"
                                          name="visitdetail"
                                          value="Returning Patient"
                                        />
                                        <span className="ml-1">
                                          Returning Patient
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    {/* TODO */}
                                    <label className="font-semibold">
                                      Appointment Date:
                                    </label>

                                    <ReactDatePicker
                                      selected={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                        props.values.appointmentdate = format(
                                          date,
                                          "MMM-dd-yyyy"
                                        );
                                      }}
                                      minDate={new Date()}
                                      excludeDates={sundays}
                                      maxDate={maxDate}
                                      name="appointDate"
                                      className="form-control"
                                    >
                                      {/* <footer>
                                        <div className="text-red-500">
                                          "Please select a date within 3 months
                                          from today"
                                        </div>
                                      </footer> */}
                                    </ReactDatePicker>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="font-semibold">
                                      Appointment Time
                                    </label>
                                    <ButtonGroup
                                      orientation="horizontal"
                                      buttons={timerRange}
                                      onButtonClick={(e) => {
                                        props.values.appointmenttime =
                                          e.target.name;
                                      }}
                                      buttonClassName="buttonstyle mt-1 ml-2"
                                      containerClassName=""
                                      activeButtonClassName="bg-blue"
                                      name="time"
                                    />
                                  </div>
                                </div>
                                <div className="mt-1 col-md-6 col-lg-6">
                                  <label className="font-semibold">
                                    Preferred Date & Time for Contact
                                  </label>
                                  <ReactDatePicker
                                    className=" form-control"
                                    selected={pstartDate}
                                    showTimeSelect
                                    onChange={(date) => {
                                      setpStartDate(date);
                                      props.values.preferredtiming = format(
                                        date,
                                        "MMM-dd-yyyy  h:mm aa"
                                      );
                                    }}
                                    excludeTimes={disableAppointTimes}
                                    minDate={new Date()}
                                    excludeDates={sundays}
                                    maxDate={maxDate}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    timeIntervals={30}
                                  />
                                  <div className="help-block with-errors"></div>
                                </div>
                                <div className="mt-1 col-md-6">
                                  <label className="font-semibold">
                                    Reason:
                                  </label>
                                  <select
                                    className="custom-select patient"
                                    onChange={(e) => {
                                      var reason = e.target.value;
                                      props.values.reason = reason;
                                      switchReason(reason);
                                    }}
                                    name="reason"
                                    tabIndex="7"
                                  >
                                    <option defaultValue={-1} value="-1">
                                      Select Reason
                                    </option>
                                    <option value="Consultation">
                                      Consultation
                                    </option>
                                    <option value="Dental Treatment">
                                      Dental Treatment
                                    </option>
                                    <option value="Cleaning - Adult">
                                      Cleaning - Adult
                                    </option>
                                    <option value="Cleaning - Child">
                                      Cleaning - Child
                                    </option>
                                    <option value="Emergency / Tooth Pain">
                                      Emergency / Tooth Pain
                                    </option>
                                    <option value="other">Other</option>
                                  </select>
                                </div>
                                <div
                                  style={{
                                    display: showInfo ? "block" : "none",
                                  }}
                                >
                                  <div className="form-group ">
                                    <TextAreaField
                                      disabled={messageDisabled}
                                      //   as={TextAreaField}
                                      label="Message:"
                                      name="message"
                                      className="form-control"
                                    />
                                  </div>
                                </div>

                                <div className="messages"></div>

                                <div className="row">
                                  <div className="items-center justify-center mt-10 text-center align-middle colx form-btn place-items-center">
                                    <button
                                      type="submit"
                                      disabled={!props.isValid || !props.dirty}
                                      className="content-center self-center col-auto btn btn-lg place-self-center btn-md bg-blue submit"
                                    >
                                      <span className="text-white">
                                        Submit Request{" "}
                                      </span>
                                    </button>

                                    <Fade in={showIsSubmit}>
                                      <div id="example-fade-text">
                                        <mark>
                                          <span className="text-red">
                                            Please wait , Your request is in
                                            progress
                                          </span>
                                        </mark>
                                      </div>
                                    </Fade>
                                  </div>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
                <Sidebar />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
