const HOME =
{
    "page": "home",
    "title": "Dentist in Colborne | Colborne Dentist – Colborne Family Dental",
    "description": "Looking for the dental clinic in Colborne? Our experienced dentists offer the highest quality dental care services in a friendly environment. Call us 905-355-1419"
};

const SERVICE =
{
    "page": "service",
    "title": "Dental Services in Colborne | Dental Services – Colborne Family Dental",
    "description": "Colborne Family Dentist offers the complete dental services"
}
const ABOUTUS = {
    "title": "About Us | Colborne Family Dental",
    "description": "Our dentists provide world-class dental care treatment in Colborne and are dedicated to protecting your oral health for a lifetime!"

}
const NEWPATIENTS = {
    "title": "New patients | Colborne Family Dental",
    "description": "Fill out a new patient’s form to book your appointments"

}

const FILEUPLOAD = {
    "title": "File Upload | Colborne Family Dental",
    "description": " Upload a file for consultation"

}

const PAYMENT = {
    "title": "Payment Process | Colborne Family Dental",
    "description": "Make a payment with one-step solution"

}

const CONTACTUS = {
    "title": "Contact us | Colborne Family Dental",
    "description": "We offer our patients the best dental care services in Colborne. Call us 905-355-1419 foR our affordable dental care facilities",
}

const NEWAPPOINTMENT = {
    "title": "Book an Appointment | Colborne Family Dental",
    "description": "Book an appointment today, we provide the best dental services in Colborne."

}

const SERVICEDENTURE = {
    "title": "Dentures in Colborne | Dentures treatment in Colborne – Colborne Family Dental",
    "description": "We create natural looking dentures that fit and work comfortably. Contact us to know more details."

}
const SERVICEIMPLANT = {
    "title": "Dental Implant Colborne | Colborne Dental Implant – Colborne Family Dental",
    "description": "Are you looking for dental implant solutions in Colborne? Look no further and get in touch with us."

}
export { NEWAPPOINTMENT, CONTACTUS, NEWPATIENTS, HOME, PAYMENT, FILEUPLOAD, SERVICE, ABOUTUS, SERVICEDENTURE, SERVICEIMPLANT }