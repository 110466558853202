import React, { useState } from "react";
import { Button } from "react-bootstrap";
import FloatingInput from "UI/FloatingInput/FloatingInput";

export default function GetDirectionsBoxMob({
    inputcss,
    btncss,
    label,
    iconcss,
    ...props
}) {
    const [showError, setshowError] = useState(false);

    const checkCurrentLocForNull = (e) => {
        e.preventDefault();
        var val = document.forms["locformMob"].saddr.value;

        if (!val || val.length <= 0 || val.trim() === "") {
            setshowError(true);
        } else {
            document.forms["locformMob"].submit();
            setshowError(false);
            return true;
        }
    };

    return (
        <>
            <form
                name="locformMob"
                action="https://maps.google.com/maps"
                method="get"
                target="_blank"
                onSubmit={checkCurrentLocForNull}
            >
                <div className="d-flex mt-2  space-x-2">
                    <div>
                        {/* <label htmlFor="saddr">{label}</label> */}
                        <FloatingInput
                            name="saddr"
                            classm={inputcss}
                            label="Enter your location"
                        ></FloatingInput>

                        <input
                            type="hidden"
                            name="daddr"
                            value="10 Victoria Square Colborne,ON K0K 1S0 Canada"
                        />
                    </div>
                    <div>
                        <Button type="submit" className={btncss} size={"large"}>
                            {/* <i className="bi bi-compass text-xs"></i> */}
                            <span className="iconcss font-semibold  text-xs">
                                Submit
                            </span>
                        </Button>
                        {/* <button type="submit" className={btncss}>Get Directions</button> */}
                    </div>
                </div>
                {showError && (
                    <span className="text-sm text-red-500">
                        * Current Location is required
                    </span>
                )}
            </form>
        </>
        // </form >
    );
}
// "bg-gray-300"
// "btn h-8 btn-outline-secondary btn-sm mt-3
