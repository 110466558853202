
export const userreviews = [{
    name: "Saanvi Malhotra",
    rating: "5",
    review: "I recently got my invisible braces from Colborne Family Dental; they gave me the best service. I felt like my home there, and all the staffs are very genuine, sincere, not phoney - if you know what I mean. Please do not hesitate to visit the clinic to take care of all your oral problems. Professional dentistry and I highly recommend."
}, {
    name: "Mickey A. Yin",
    rating: "5",
    review: "Just moved to the area and needed a dentist. The dentist and staffs at Colborne Family Dental were friendly. I had my hygiene done, great hygienist, excellent job, highly recommend Colborne dental."
},
{
    name: "Melissa J. Buono",
    rating: "5",
    review: "I had a dental emergency, got an appointment same day, great dentist and staffs. Pain free now:). Thank you for such an amazing service."
},
{
    name: "Sherry George",
    rating: "5",
    review: "I just recently had my teeth cleaned and dental work done at Colborne Dental. I am very pleased with the work they did on my teeth. They were professional, understanding and a great, positive, upbeat environment to be in. Thank you!"
},
{
    name: "kate lawrence",
    rating: "5",
    review: "Amazing staff!! Have always been on time, staff are so nice to my son who's been going there since he was three. They always tell him why he needs to brush and always so kind to him and myself. I can honestly say I could never go anywhere else! Thank you for always making my son excited to go to the dentist!"
},
{
    name: "Matt DeShane",
    rating: "5",
    review: "Always a postitive experience. Friendly staff makes everyone comfortable. The quality of work is always excellent. Look forward to going to our appointments. Kid friendly."
},

]