import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="bg-gray-500">
        <div className="container py-20 mt-8 text-center ">
          <div className="inline-block mx-auto align-middle ">
            <h1 className="text-red-300 text-9xl font-Poppins">404</h1>
            <h1 className="py-2 text-white text-7xl font-Poppins">
              oops! Page not found
            </h1>
            <h1 className="text-2xl text-white font-Poppins">
              Oops! The page you are looking for does not exist. It might have
              been moved or deleted.
            </h1>
            <Link to="/">Home Page</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
