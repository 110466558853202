import React from "react";
import { Link } from "react-router-dom";

export default function WisdomTeethExtraction() {
  return (
    <>
      <div className="container mb-5x">
        <h1 className="text-center h1"> Wisdom Teeth Extraction</h1>
        <div className="flex flex-wrap w-3/5 gap-5 py-2 mx-auto">
          <img
            src="imagesx/colborne/services/WisdomTeethExtraction/1.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md "
          />
          <img
            src="imagesx/colborne/services/WisdomTeethExtraction/2.jpeg"
            alt="amalagam free"
            className="rounded-md shadow-md"
          />
        </div>
        <div className="mb-10">
          <p className="py-1 para">
            Teeth extractions are necessary when a tooth is too decayed to
            restore through bonding/crowns, because of overcrowding, or to make
            room for orthodontic treatments. One of the most common reasons for
            a dental extraction is for wisdom teeth.
          </p>
          <p className="py-1 para">
            Located at the back of both the upper and lower jaw, wisdom teeth
            start to grow and typically appear in our late teen years or early
            20s. While each patient’s case may vary, wisdom teeth can cause
            issues such as pain, inflammation, and infection, for the
            surrounding teeth.
          </p>
          <p className="py-1 para">
            Wisdom teeth may also cause pain and swelling in the gums, as well
            as increasing the chances of cysts to develop. Everyone’s wisdom
            teeth will grow in differently, making it important for our dental
            team to monitor its impact on other teeth and how many you have.
            People commonly have between 1-4 wisdom teeth that grow in; however,
            there are rare cases in which people do not have any wisdom teeth at
            all, or even circumstances when the patient has more than four.
            Depending on the way the wisdom tooth/teeth are growing in, they
            will not need to be removed if they are not making a negative impact
            on the other teeth and your jaw can accommodate them.
          </p>
          <p className="py-1 para">
            Your dentist will assess the state and long term effects of your
            wisdom teeth through digital x-rays and examinations.Prior to the
            procedure of your wisdom teeth extraction, you will be given an
            anesthesia to ensure you feel comfortable during the surgery.
          </p>
          <p className="py-1">
            <Link to="/appointment">
              {" "}
              <span className="font-semibold text-blue-700">
                Contact Colborne Dental{" "}
              </span>
            </Link>
            to schedule a visit and evaluate the status of your wisdom teeth
          </p>
        </div>
      </div>
    </>
  );
}
