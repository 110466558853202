import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Fade } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { InputField } from "UI/InputField";
import { TextAreaField } from "UI/TextAreaField";
import { validate } from "./formk";
import "./styles.css";
import { SendContactUsEnquiry } from "services/ContactUsapi";

export default function ContactFormNew() {
    // const emailInput = useAutoFocus();
    const [showIsSubmit, setshowIsSubmit] = useState(false);

    //#region State
    var today = new Date(), date = today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
    //var defaultDate = format(new Date(), "MMM-dd-yyyy");
    const [messageDisabled, setmessageDisabled] = useState(true);
    const [response, setResponse] = useState({});
    const [alertvariant, Setalertvariant] = useState("success");


    const [showInfo, setshowInfo] = useState(false);

    // const nameInput = useRef(null);
    const [show, setShow] = useState(false);

    //#endregion

    //const ref = useRef(null);

    const handleSubmit = (values, actions) => {
        setshowIsSubmit(true);

        submitQuery(values, actions);
    };

    function submitQuery(contactus, actions) {

        contactus.requestdate = date;

        SendContactUsEnquiry(contactus)
            .then((response) => {
                // console.log(response);

                setResponse({
                    note: "Request Id : " + response,
                    message: contactus.message,
                });

                Setalertvariant("success");
                setShow(true);
            })
            .catch((err) => {
                setResponse({
                    note: "",
                    message: "Request failed , Please try again",
                });
                Setalertvariant("warning");
                setShow(true);

                setTimeout(() => {
                    setShow(false);
                }, 3000);
            })
            .finally(() => {
                setshowIsSubmit(false);
                actions.setSubmitting(false);
                actions.resetForm();
            });
    }



    return (
        <>
            {show && (
                <Alert
                    id="aptAlert"
                    variant={alertvariant}
                    closeLabel="Close"
                    onClose={() => {
                        setShow(false);
                        setResponse({});
                    }}
                    dismissible
                >
                    <h3>Your query has been sent successfully </h3>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setShow(false)} variant="outline-yellow">
                            <i className="bi bi-x-circle"></i> Close
                        </Button>
                    </div>
                </Alert>
            )}
            {/* <div id="apt" className="container"> */}
            <section
                id="services-7"
                className="w-full bg-lightgreyx servicess-section division "
            >
                <div className="mb-20 form-holder">
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            contact: "",
                            name: "",
                            message: "",


                        }}
                        validationSchema={validate}
                        onSubmit={handleSubmit}
                    >
                        {(props) => {
                            return (
                                <Form
                                    onSubmit={
                                        props.isSubmitting
                                            ? (e) => {
                                                e.preventDefault();
                                            }
                                            : props.handleSubmit
                                    }
                                    name="contactusForm"
                                    className="row contact-form"
                                >
                                    <div className="flex flex-wrap md:w-1/2x md:space-x-9 lg:space-x-9">
                                        <div className="md:1/2">
                                            <label
                                                className="block text-sm font-semibold tracking-wide text-gray-700 uppercase mb-2x"
                                                htmlFor="name"
                                            >
                                                * Name
                                            </label>
                                            <InputField
                                                autoFocus
                                                tabIndex={1}
                                                required={true}
                                                type="text"
                                                name="name"
                                                autoComplete="off"
                                                placeholder="Name"
                                                onBlur={props.handleBlur}
                                            />
                                        </div>
                                        <div className="md:w-1/2">
                                            <label
                                                className="block text-sm font-semibold tracking-wide text-gray-700 uppercase mb-2x"
                                                htmlFor="contact"
                                            >
                                                E-mail / Contact Number
                                            </label>
                                            <InputField
                                                autoComplete="off"
                                                required={true}
                                                tabIndex={2}
                                                type="text"
                                                name="contact"

                                                onBlur={props.handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        <div className="w-full">
                                            <label
                                                className="block mb-2 text-sm font-bold tracking-wide text-gray-700 uppercase"
                                                htmlFor="message"
                                            >
                                                * Message
                                            </label>
                                            <div className="w-1/2x">
                                                <TextAreaField
                                                    tabIndex={4}
                                                    name="message"
                                                    required={true}

                                                    className="w-full border-2 border-gray-200"
                                                    placeholder="Message"
                                                    onBlur={props.handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        {/* <div
                                            className={`transition-all duration-200	 ${showIsSubmit ? "opacity-100" : "opacity-0"
                                                }`}
                                        >
                                            <div id="example-fade-text">
                                                <mark>
                                                    <span className="text-red">
                                                        Please wait , Your request is in progress
                                                    </span>
                                                </mark>
                                            </div>
                                        </div> */}
                                        <Fade in={showIsSubmit}>
                                            <div id="example-fade-text">
                                                <mark>
                                                    <span className="text-red">
                                                        Please wait , Your request is in
                                                        progress
                                                    </span>
                                                </mark>
                                            </div>
                                        </Fade>
                                        <div className="items-center justify-center text-center align-middle  form-btn place-items-center">
                                            <Button
                                                disabled={!props.isValid || !props.dirty}
                                                value="upload"
                                                size={"lg"}
                                                type="submit"
                                            >
                                                Submit Query
                                            </Button>
                                        </div>

                                        {/* </Fade> */}
                                    </div>
                                    {/* </div> */}
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </section>
            {/* </div> */}
        </>
    );
}
