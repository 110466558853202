import Contact from "components/Contact/Contact";
import Loading from "components/Loading";
import ScrollButton from "components/ScrollTop/ScrollButton";
import React, { lazy, Suspense, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import HeroLandingPage from "./HeroLandingPage";
import { HOME } from "assets/helmet";
import PromotionMonthly from "./PromotionMonthly";
import { HelmetTag } from "components/HelmetTag";
import Popup from "components/PopUp/default";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ServicesIntroHome = lazy(() =>
    import("components/OurServices/ServicesIntro")
  );

  const WhyusHome = lazy(() => import("components/WhyUs/Whyus"));
  const ReviewHome = lazy(() => import("components/Reviews/reviews"));
  const HygienistHome = lazy(() => import("./Hygienist"));
  const AffHome = lazy(() => import("components/Affiliations/Aff"));

  // Pop up code
  const [ showPopup, setShowPopup ] = useState(false);
  useEffect(() => {
    const popupShown = sessionStorage.getItem('popupShown');
    if (!popupShown) {
      setShowPopup(true);
      sessionStorage.setItem('popupShown', 'true');
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  return (
    <>

      <HelmetTag title={HOME.title} desc={HOME.description} />
      {/* Popup up code*/}
      <Popup show={showPopup} onClose={handleClosePopup} />

      <div id="page" className="page">
        <div className="hero-sectionx">
          <HeroLandingPage />
        </div>
        <Container>
          <Suspense fallback={<Loading />}>
            <ServicesIntroHome />
            <PromotionMonthly />
            <WhyusHome />
            <ReviewHome />
            <HygienistHome />
            {/* <div id="location">
              <LocationHome enable={false} />
            </div> */}
            <div id="contactus">
              <Contact />
            </div>
            <AffHome />
          </Suspense>
        </Container>
      </div>
      <ScrollButton />
    </>
  );
};

export default Home;
